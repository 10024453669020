import './style.less';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import App from './components/App';
import { configure } from 'mobx';
import { initGRPC } from 'utils/grpcUtil';

configure({
  enforceActions: 'never'
});

initGRPC();
const root = createRoot(document.getElementById('main-view-wrapper')!);
const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center' }}>
    <img src={require('./assets/no-data.png')} alt="" />
    <p>暂无内容</p>
  </div>
);

root.render(
  <ConfigProvider locale={zhCN} renderEmpty={customizeRenderEmpty}>
    <App />
  </ConfigProvider>
);
