// source: atom/v2/billing.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var github_com_alta_protopatch_patch_go_pb = require('../../github.com/alta/protopatch/patch/go_pb.js');
goog.object.extend(proto, github_com_alta_protopatch_patch_go_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var meta_meta_pb = require('../../meta/meta_pb.js');
goog.object.extend(proto, meta_meta_pb);
var atom_v2_pricing_pb = require('../../atom/v2/pricing_pb.js');
goog.object.extend(proto, atom_v2_pricing_pb);
goog.exportSymbol('proto.atom.v2.Billing', null, global);
goog.exportSymbol('proto.atom.v2.BillingItemDetail', null, global);
goog.exportSymbol('proto.atom.v2.BillingStatus', null, global);
goog.exportSymbol('proto.atom.v2.CreateBillingReq', null, global);
goog.exportSymbol('proto.atom.v2.GetBillingReq', null, global);
goog.exportSymbol('proto.atom.v2.ListBillingsReq', null, global);
goog.exportSymbol('proto.atom.v2.ListBillingsRes', null, global);
goog.exportSymbol('proto.atom.v2.RemoveBillingReq', null, global);
goog.exportSymbol('proto.atom.v2.UpdateBillingReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.Billing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.atom.v2.Billing.repeatedFields_, null);
};
goog.inherits(proto.atom.v2.Billing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.Billing.displayName = 'proto.atom.v2.Billing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.BillingItemDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.BillingItemDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.BillingItemDetail.displayName = 'proto.atom.v2.BillingItemDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.CreateBillingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.CreateBillingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.CreateBillingReq.displayName = 'proto.atom.v2.CreateBillingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.GetBillingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.GetBillingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.GetBillingReq.displayName = 'proto.atom.v2.GetBillingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.ListBillingsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.atom.v2.ListBillingsReq.repeatedFields_, null);
};
goog.inherits(proto.atom.v2.ListBillingsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.ListBillingsReq.displayName = 'proto.atom.v2.ListBillingsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.ListBillingsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.atom.v2.ListBillingsRes.repeatedFields_, null);
};
goog.inherits(proto.atom.v2.ListBillingsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.ListBillingsRes.displayName = 'proto.atom.v2.ListBillingsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.UpdateBillingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.UpdateBillingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.UpdateBillingReq.displayName = 'proto.atom.v2.UpdateBillingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.RemoveBillingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.RemoveBillingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.RemoveBillingReq.displayName = 'proto.atom.v2.RemoveBillingReq';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.atom.v2.Billing.repeatedFields_ = [99];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.Billing.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.Billing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.Billing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.Billing.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && meta_meta_pb.ID.toObject(includeInstance, f),
    billingmonth: (f = msg.getBillingmonth()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0),
    discount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tenantid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    tenantname: jspb.Message.getFieldWithDefault(msg, 11, ""),
    detailsList: jspb.Message.toObjectList(msg.getDetailsList(),
    proto.atom.v2.BillingItemDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.Billing}
 */
proto.atom.v2.Billing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.Billing;
  return proto.atom.v2.Billing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.Billing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.Billing}
 */
proto.atom.v2.Billing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBillingmonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDiscount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaid(value);
      break;
    case 7:
      var value = /** @type {!proto.atom.v2.BillingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantname(value);
      break;
    case 99:
      var value = new proto.atom.v2.BillingItemDetail;
      reader.readMessage(value,proto.atom.v2.BillingItemDetail.deserializeBinaryFromReader);
      msg.addDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.Billing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.Billing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.Billing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.Billing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getBillingmonth();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDiscount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPaid();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTenantname();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      99,
      f,
      proto.atom.v2.BillingItemDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional meta.ID id = 1;
 * @return {?proto.meta.ID}
 */
proto.atom.v2.Billing.prototype.getId = function() {
  return /** @type{?proto.meta.ID} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.ID, 1));
};


/**
 * @param {?proto.meta.ID|undefined} value
 * @return {!proto.atom.v2.Billing} returns this
*/
proto.atom.v2.Billing.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.Billing} returns this
 */
proto.atom.v2.Billing.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.Billing.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp billingMonth = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.atom.v2.Billing.prototype.getBillingmonth = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.atom.v2.Billing} returns this
*/
proto.atom.v2.Billing.prototype.setBillingmonth = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.Billing} returns this
 */
proto.atom.v2.Billing.prototype.clearBillingmonth = function() {
  return this.setBillingmonth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.Billing.prototype.hasBillingmonth = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 total = 3;
 * @return {number}
 */
proto.atom.v2.Billing.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.atom.v2.Billing} returns this
 */
proto.atom.v2.Billing.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 discount = 4;
 * @return {number}
 */
proto.atom.v2.Billing.prototype.getDiscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.atom.v2.Billing} returns this
 */
proto.atom.v2.Billing.prototype.setDiscount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 paid = 6;
 * @return {number}
 */
proto.atom.v2.Billing.prototype.getPaid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.atom.v2.Billing} returns this
 */
proto.atom.v2.Billing.prototype.setPaid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional BillingStatus status = 7;
 * @return {!proto.atom.v2.BillingStatus}
 */
proto.atom.v2.Billing.prototype.getStatus = function() {
  return /** @type {!proto.atom.v2.BillingStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.atom.v2.BillingStatus} value
 * @return {!proto.atom.v2.Billing} returns this
 */
proto.atom.v2.Billing.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.atom.v2.Billing.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.atom.v2.Billing} returns this
*/
proto.atom.v2.Billing.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.Billing} returns this
 */
proto.atom.v2.Billing.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.Billing.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.atom.v2.Billing.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.atom.v2.Billing} returns this
*/
proto.atom.v2.Billing.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.Billing} returns this
 */
proto.atom.v2.Billing.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.Billing.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string tenantID = 10;
 * @return {string}
 */
proto.atom.v2.Billing.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.Billing} returns this
 */
proto.atom.v2.Billing.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string tenantName = 11;
 * @return {string}
 */
proto.atom.v2.Billing.prototype.getTenantname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.Billing} returns this
 */
proto.atom.v2.Billing.prototype.setTenantname = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated BillingItemDetail details = 99;
 * @return {!Array<!proto.atom.v2.BillingItemDetail>}
 */
proto.atom.v2.Billing.prototype.getDetailsList = function() {
  return /** @type{!Array<!proto.atom.v2.BillingItemDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.atom.v2.BillingItemDetail, 99));
};


/**
 * @param {!Array<!proto.atom.v2.BillingItemDetail>} value
 * @return {!proto.atom.v2.Billing} returns this
*/
proto.atom.v2.Billing.prototype.setDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 99, value);
};


/**
 * @param {!proto.atom.v2.BillingItemDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.atom.v2.BillingItemDetail}
 */
proto.atom.v2.Billing.prototype.addDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 99, opt_value, proto.atom.v2.BillingItemDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.atom.v2.Billing} returns this
 */
proto.atom.v2.Billing.prototype.clearDetailsList = function() {
  return this.setDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.BillingItemDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.BillingItemDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.BillingItemDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.BillingItemDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    productid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    instanceid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    resourcetype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    usageamount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    unitprice: jspb.Message.getFieldWithDefault(msg, 5, 0),
    cost: jspb.Message.getFieldWithDefault(msg, 6, 0),
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    unit: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.BillingItemDetail}
 */
proto.atom.v2.BillingItemDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.BillingItemDetail;
  return proto.atom.v2.BillingItemDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.BillingItemDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.BillingItemDetail}
 */
proto.atom.v2.BillingItemDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourcetype(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsageamount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitprice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCost(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 9:
      var value = /** @type {!proto.atom.v2.Unit} */ (reader.readEnum());
      msg.setUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.BillingItemDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.BillingItemDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.BillingItemDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.BillingItemDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstanceid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResourcetype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsageamount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUnitprice();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCost();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * optional string productID = 1;
 * @return {string}
 */
proto.atom.v2.BillingItemDetail.prototype.getProductid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.BillingItemDetail} returns this
 */
proto.atom.v2.BillingItemDetail.prototype.setProductid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string instanceID = 2;
 * @return {string}
 */
proto.atom.v2.BillingItemDetail.prototype.getInstanceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.BillingItemDetail} returns this
 */
proto.atom.v2.BillingItemDetail.prototype.setInstanceid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string resourceType = 3;
 * @return {string}
 */
proto.atom.v2.BillingItemDetail.prototype.getResourcetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.BillingItemDetail} returns this
 */
proto.atom.v2.BillingItemDetail.prototype.setResourcetype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 usageAmount = 4;
 * @return {number}
 */
proto.atom.v2.BillingItemDetail.prototype.getUsageamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.atom.v2.BillingItemDetail} returns this
 */
proto.atom.v2.BillingItemDetail.prototype.setUsageamount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 unitPrice = 5;
 * @return {number}
 */
proto.atom.v2.BillingItemDetail.prototype.getUnitprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.atom.v2.BillingItemDetail} returns this
 */
proto.atom.v2.BillingItemDetail.prototype.setUnitprice = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 cost = 6;
 * @return {number}
 */
proto.atom.v2.BillingItemDetail.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.atom.v2.BillingItemDetail} returns this
 */
proto.atom.v2.BillingItemDetail.prototype.setCost = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp startDate = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.atom.v2.BillingItemDetail.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.atom.v2.BillingItemDetail} returns this
*/
proto.atom.v2.BillingItemDetail.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.BillingItemDetail} returns this
 */
proto.atom.v2.BillingItemDetail.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.BillingItemDetail.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.atom.v2.BillingItemDetail.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.atom.v2.BillingItemDetail} returns this
*/
proto.atom.v2.BillingItemDetail.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.BillingItemDetail} returns this
 */
proto.atom.v2.BillingItemDetail.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.BillingItemDetail.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Unit unit = 9;
 * @return {!proto.atom.v2.Unit}
 */
proto.atom.v2.BillingItemDetail.prototype.getUnit = function() {
  return /** @type {!proto.atom.v2.Unit} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.atom.v2.Unit} value
 * @return {!proto.atom.v2.BillingItemDetail} returns this
 */
proto.atom.v2.BillingItemDetail.prototype.setUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.CreateBillingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.CreateBillingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.CreateBillingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.CreateBillingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    billing: (f = msg.getBilling()) && proto.atom.v2.Billing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.CreateBillingReq}
 */
proto.atom.v2.CreateBillingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.CreateBillingReq;
  return proto.atom.v2.CreateBillingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.CreateBillingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.CreateBillingReq}
 */
proto.atom.v2.CreateBillingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.atom.v2.Billing;
      reader.readMessage(value,proto.atom.v2.Billing.deserializeBinaryFromReader);
      msg.setBilling(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.CreateBillingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.CreateBillingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.CreateBillingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.CreateBillingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBilling();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.atom.v2.Billing.serializeBinaryToWriter
    );
  }
};


/**
 * optional Billing billing = 1;
 * @return {?proto.atom.v2.Billing}
 */
proto.atom.v2.CreateBillingReq.prototype.getBilling = function() {
  return /** @type{?proto.atom.v2.Billing} */ (
    jspb.Message.getWrapperField(this, proto.atom.v2.Billing, 1));
};


/**
 * @param {?proto.atom.v2.Billing|undefined} value
 * @return {!proto.atom.v2.CreateBillingReq} returns this
*/
proto.atom.v2.CreateBillingReq.prototype.setBilling = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.CreateBillingReq} returns this
 */
proto.atom.v2.CreateBillingReq.prototype.clearBilling = function() {
  return this.setBilling(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.CreateBillingReq.prototype.hasBilling = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.GetBillingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.GetBillingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.GetBillingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.GetBillingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.GetBillingReq}
 */
proto.atom.v2.GetBillingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.GetBillingReq;
  return proto.atom.v2.GetBillingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.GetBillingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.GetBillingReq}
 */
proto.atom.v2.GetBillingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.GetBillingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.GetBillingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.GetBillingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.GetBillingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.atom.v2.GetBillingReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.GetBillingReq} returns this
 */
proto.atom.v2.GetBillingReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.atom.v2.ListBillingsReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.ListBillingsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.ListBillingsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.ListBillingsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListBillingsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    statusList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    alltenants: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    pager: (f = msg.getPager()) && meta_meta_pb.PagerReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.ListBillingsReq}
 */
proto.atom.v2.ListBillingsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.ListBillingsReq;
  return proto.atom.v2.ListBillingsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.ListBillingsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.ListBillingsReq}
 */
proto.atom.v2.ListBillingsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.atom.v2.BillingStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatus(values[i]);
      }
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlltenants(value);
      break;
    case 99:
      var value = new meta_meta_pb.PagerReq;
      reader.readMessage(value,meta_meta_pb.PagerReq.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.ListBillingsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.ListBillingsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.ListBillingsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListBillingsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getAlltenants();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      99,
      f,
      meta_meta_pb.PagerReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp startTime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.atom.v2.ListBillingsReq.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.atom.v2.ListBillingsReq} returns this
*/
proto.atom.v2.ListBillingsReq.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.ListBillingsReq} returns this
 */
proto.atom.v2.ListBillingsReq.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.ListBillingsReq.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp endTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.atom.v2.ListBillingsReq.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.atom.v2.ListBillingsReq} returns this
*/
proto.atom.v2.ListBillingsReq.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.ListBillingsReq} returns this
 */
proto.atom.v2.ListBillingsReq.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.ListBillingsReq.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated BillingStatus status = 3;
 * @return {!Array<!proto.atom.v2.BillingStatus>}
 */
proto.atom.v2.ListBillingsReq.prototype.getStatusList = function() {
  return /** @type {!Array<!proto.atom.v2.BillingStatus>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.atom.v2.BillingStatus>} value
 * @return {!proto.atom.v2.ListBillingsReq} returns this
 */
proto.atom.v2.ListBillingsReq.prototype.setStatusList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.atom.v2.BillingStatus} value
 * @param {number=} opt_index
 * @return {!proto.atom.v2.ListBillingsReq} returns this
 */
proto.atom.v2.ListBillingsReq.prototype.addStatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.atom.v2.ListBillingsReq} returns this
 */
proto.atom.v2.ListBillingsReq.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * optional bool allTenants = 4;
 * @return {boolean}
 */
proto.atom.v2.ListBillingsReq.prototype.getAlltenants = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.atom.v2.ListBillingsReq} returns this
 */
proto.atom.v2.ListBillingsReq.prototype.setAlltenants = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional meta.PagerReq pager = 99;
 * @return {?proto.meta.PagerReq}
 */
proto.atom.v2.ListBillingsReq.prototype.getPager = function() {
  return /** @type{?proto.meta.PagerReq} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerReq, 99));
};


/**
 * @param {?proto.meta.PagerReq|undefined} value
 * @return {!proto.atom.v2.ListBillingsReq} returns this
*/
proto.atom.v2.ListBillingsReq.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 99, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.ListBillingsReq} returns this
 */
proto.atom.v2.ListBillingsReq.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.ListBillingsReq.prototype.hasPager = function() {
  return jspb.Message.getField(this, 99) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.atom.v2.ListBillingsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.ListBillingsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.ListBillingsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.ListBillingsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListBillingsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    billingsList: jspb.Message.toObjectList(msg.getBillingsList(),
    proto.atom.v2.Billing.toObject, includeInstance),
    pager: (f = msg.getPager()) && meta_meta_pb.PagerRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.ListBillingsRes}
 */
proto.atom.v2.ListBillingsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.ListBillingsRes;
  return proto.atom.v2.ListBillingsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.ListBillingsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.ListBillingsRes}
 */
proto.atom.v2.ListBillingsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.atom.v2.Billing;
      reader.readMessage(value,proto.atom.v2.Billing.deserializeBinaryFromReader);
      msg.addBillings(value);
      break;
    case 2:
      var value = new meta_meta_pb.PagerRes;
      reader.readMessage(value,meta_meta_pb.PagerRes.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.ListBillingsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.ListBillingsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.ListBillingsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListBillingsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.atom.v2.Billing.serializeBinaryToWriter
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      meta_meta_pb.PagerRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Billing billings = 1;
 * @return {!Array<!proto.atom.v2.Billing>}
 */
proto.atom.v2.ListBillingsRes.prototype.getBillingsList = function() {
  return /** @type{!Array<!proto.atom.v2.Billing>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.atom.v2.Billing, 1));
};


/**
 * @param {!Array<!proto.atom.v2.Billing>} value
 * @return {!proto.atom.v2.ListBillingsRes} returns this
*/
proto.atom.v2.ListBillingsRes.prototype.setBillingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.atom.v2.Billing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.atom.v2.Billing}
 */
proto.atom.v2.ListBillingsRes.prototype.addBillings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.atom.v2.Billing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.atom.v2.ListBillingsRes} returns this
 */
proto.atom.v2.ListBillingsRes.prototype.clearBillingsList = function() {
  return this.setBillingsList([]);
};


/**
 * optional meta.PagerRes pager = 2;
 * @return {?proto.meta.PagerRes}
 */
proto.atom.v2.ListBillingsRes.prototype.getPager = function() {
  return /** @type{?proto.meta.PagerRes} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerRes, 2));
};


/**
 * @param {?proto.meta.PagerRes|undefined} value
 * @return {!proto.atom.v2.ListBillingsRes} returns this
*/
proto.atom.v2.ListBillingsRes.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.ListBillingsRes} returns this
 */
proto.atom.v2.ListBillingsRes.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.ListBillingsRes.prototype.hasPager = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.UpdateBillingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.UpdateBillingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.UpdateBillingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.UpdateBillingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    total: jspb.Message.getFieldWithDefault(msg, 4, 0),
    discount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    paid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.UpdateBillingReq}
 */
proto.atom.v2.UpdateBillingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.UpdateBillingReq;
  return proto.atom.v2.UpdateBillingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.UpdateBillingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.UpdateBillingReq}
 */
proto.atom.v2.UpdateBillingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDiscount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaid(value);
      break;
    case 7:
      var value = /** @type {!proto.atom.v2.BillingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.UpdateBillingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.UpdateBillingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.UpdateBillingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.UpdateBillingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDiscount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPaid();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional string tenantID = 2;
 * @return {string}
 */
proto.atom.v2.UpdateBillingReq.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.UpdateBillingReq} returns this
 */
proto.atom.v2.UpdateBillingReq.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.atom.v2.UpdateBillingReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.UpdateBillingReq} returns this
 */
proto.atom.v2.UpdateBillingReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 total = 4;
 * @return {number}
 */
proto.atom.v2.UpdateBillingReq.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.atom.v2.UpdateBillingReq} returns this
 */
proto.atom.v2.UpdateBillingReq.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 discount = 5;
 * @return {number}
 */
proto.atom.v2.UpdateBillingReq.prototype.getDiscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.atom.v2.UpdateBillingReq} returns this
 */
proto.atom.v2.UpdateBillingReq.prototype.setDiscount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 paid = 6;
 * @return {number}
 */
proto.atom.v2.UpdateBillingReq.prototype.getPaid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.atom.v2.UpdateBillingReq} returns this
 */
proto.atom.v2.UpdateBillingReq.prototype.setPaid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional BillingStatus status = 7;
 * @return {!proto.atom.v2.BillingStatus}
 */
proto.atom.v2.UpdateBillingReq.prototype.getStatus = function() {
  return /** @type {!proto.atom.v2.BillingStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.atom.v2.BillingStatus} value
 * @return {!proto.atom.v2.UpdateBillingReq} returns this
 */
proto.atom.v2.UpdateBillingReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.RemoveBillingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.RemoveBillingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.RemoveBillingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.RemoveBillingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.RemoveBillingReq}
 */
proto.atom.v2.RemoveBillingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.RemoveBillingReq;
  return proto.atom.v2.RemoveBillingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.RemoveBillingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.RemoveBillingReq}
 */
proto.atom.v2.RemoveBillingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.RemoveBillingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.RemoveBillingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.RemoveBillingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.RemoveBillingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.atom.v2.RemoveBillingReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.RemoveBillingReq} returns this
 */
proto.atom.v2.RemoveBillingReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenantID = 2;
 * @return {string}
 */
proto.atom.v2.RemoveBillingReq.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.RemoveBillingReq} returns this
 */
proto.atom.v2.RemoveBillingReq.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.atom.v2.BillingStatus = {
  STATUSUNKNOWN: 0,
  STATUSBILLED: 1,
  STATUSUNBILLED: 2,
  STATUSPAID: 3,
  STATUSCANCELLED: 6
};

goog.object.extend(exports, proto.atom.v2);
