import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, PageHeader, Popconfirm, Tabs, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import Api from 'api/Api';
import { forEach } from 'async';
import InvitationDialog from 'components/InvitationDialog';
import MemberInfoDialog from 'components/MemberInfoDialog';
import AtomTable from 'components/Table';
import { compact, pull, sortBy, union } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useEffect } from 'react';
import Pager from 'src/stores/pager';
import { userPortalStore } from 'src/stores/userPortal';
import { Invitation } from 'types/invitation';
import { Member } from 'types/member';
import { Organization } from 'types/organization';
import { format } from 'utils/timeUtil';

class Status {
  members = {
    data: [] as Member.AsObject[],
    filteredData: [] as Member.AsObject[],
    total: 0,
    pager: new Pager(),
    search: {
      name: ''
    },
    loading: false
  };

  invitationJournals = {
    data: [] as Invitation.AsObject[],
    total: 0,
    pager: new Pager(),
    loading: false
  };

  invitationDialogOpen = false;

  memberInfoDialog = {
    open: false,
    info: undefined as Member.AsObject | undefined
  };

  constructor() {
    makeAutoObservable(this);
  }
}

const status = new Status();

const OrgMember = observer(() => {
  const { members, invitationJournals } = status;

  const fetchMembers = async () => {
    status.members.loading = true;
    const { user_groups: groups = [] } = await Api.organization.listUserGroups(userPortalStore.user.tenant_id);

    const members = await Api.member.listOrganizationMembers(userPortalStore.user.tenant_name, {
      state: [Member.State.Active, Member.State.Locked]
    });

    members.sort((a, b) => new Date(b.joined_at).getTime() - new Date(a.joined_at).getTime());
    members?.forEach((member) => {
      if (member.user_groups) {
        // @ts-ignore
        member.user_groups = member.user_groups.map((id) => {
          // @ts-ignore
          return groups.find((group) => group.id === id);
        });
      }
    });

    // status.members.data = sortBy(
    //   data,
    //   (a) => -userPortalStore.org.managers.includes(a.name),
    //   (b) => b.name
    // );
    status.members.data = members;
    status.members.filteredData = members;
    status.members.loading = false;
  };

  const fetchInvitationJournals = async () => {
    status.invitationJournals.loading = true;
    const data = await Api.invitation.listInvitationJournals({ organization: userPortalStore.user.tenant_name });

    status.invitationJournals.data = sortBy(data.invitations, (v) => -moment(v.created_at));
    // status.invitationJournals.pager.set(data.page);
    status.invitationJournals.loading = false;
  };

  const fetchOrganization = async () => {
    const data = await Api.organization.getOrganization(userPortalStore.user.tenant_name);
    userPortalStore.org = data;
  };

  const searchMembers = () => {
    status.members.filteredData = status.members.data.filter((i) => i.name.includes(status.members.search.name));
  };

  useEffect(() => {
    fetchMembers();
    fetchInvitationJournals();
  }, []);

  const memberColumns: ColumnProps<Member.AsObject>[] = [
    {
      title: '用户名',
      width: 240,
      // dataIndex: 'name'
      onCell() {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 240
          }
        };
      },
      render(value, record, index) {
        return (
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={record.user?.email}>
            {record.user?.fullname || '-'}&nbsp;({record.user?.email || '-'})
          </div>
        );
      }
    },
    {
      title: '角色',
      width: 120,
      render(value, record) {
        return userPortalStore.org?.managers?.includes(record.name) ? '管理员' : '成员';
      }
    },
    {
      title: '用户组',
      dataIndex: 'user_groups',
      width: 400,
      render(value, record, index) {
        return compact(value)?.map((v: any) => (
          <Tag
            style={{
              background: '#F4F7FE',
              border: 0,
              padding: '4px 12px'
            }}
          >
            {v.name}
          </Tag>
        ));
      }
    },
    {
      title: '状态',
      dataIndex: 'state',
      width: 80,
      render(value) {
        switch (value) {
          case Member.State.Active:
            return (
              <Tag color="green" style={{ marginRight: 0 }}>
                正常
              </Tag>
            );
          case Member.State.Locked:
            return (
              <Tag color="yellow" style={{ marginRight: 0 }}>
                锁定
              </Tag>
            );
          default:
            return '';
        }
      }
    },
    {
      title: '加入日期',
      ellipsis: true,
      dataIndex: 'joined_at',
      width: 200,
      render(value) {
        if (!value || moment(value).valueOf() === -62135596800000) {
          return '';
        }
        return moment(value).format('YYYY-MM-DD HH:mm:ss');
      }
    },
    {
      title: '操作',
      width: 280,
      fixed: 'right',
      render: (value, record) => {
        return (
          userPortalStore.org?.state === Organization.State.Active &&
          userPortalStore.org?.managers?.includes(userPortalStore.user.name) && (
            <div>
              {!userPortalStore.org?.managers?.includes(record.name) && record.state === Member.State.Active && (
                <Popconfirm
                  title="确认要将该用户设置为管理员么？"
                  onConfirm={async () => {
                    await Api.organization.updateOrganization(userPortalStore.org?.name!, {
                      managers: [...userPortalStore.org?.managers!, record.name]
                    });
                    await fetchOrganization();
                    await fetchMembers();
                  }}
                >
                  <Button type="link" size="small">
                    设置为管理员
                  </Button>
                </Popconfirm>
              )}
              {userPortalStore.org?.managers?.includes(record.name) && record.state === Member.State.Active && (
                <Popconfirm
                  title="确认要将该用户解除管理员么？"
                  disabled={userPortalStore.org?.managers?.length <= 1}
                  onConfirm={async () => {
                    const managers = userPortalStore.org?.managers?.filter((i) => i !== record.name);
                    await Api.organization.updateOrganization(userPortalStore.org?.name!, {
                      managers
                    });
                    await fetchOrganization();
                    await fetchMembers();
                  }}
                >
                  <Button type="link" size="small" disabled={userPortalStore.org?.managers?.length <= 1}>
                    解除管理员
                  </Button>
                </Popconfirm>
              )}
              {record.state === Member.State.Active && (
                <Popconfirm
                  title="确认要移除这个成员么？"
                  disabled={
                    userPortalStore.org?.managers?.includes(record.name) && userPortalStore.org?.managers?.length <= 1
                  }
                  onConfirm={async () => {
                    await Api.member.updateOrganizationMember(userPortalStore.org?.name!, record.name, {
                      state: Member.State.Left
                    });
                    await fetchMembers();
                  }}
                >
                  <Button
                    type="link"
                    size="small"
                    disabled={
                      userPortalStore.org?.managers?.includes(record.name) && userPortalStore.org?.managers?.length <= 1
                    }
                  >
                    移除
                  </Button>
                </Popconfirm>
              )}
              {record.state === Member.State.Active && (
                <Popconfirm
                  title="确认要锁定这个成员么？"
                  onConfirm={async () => {
                    await Api.member.updateOrganizationMember(userPortalStore.org?.name!, record.name, {
                      state: Member.State.Locked
                    });
                    await fetchMembers();
                  }}
                >
                  <Button type="link" size="small">
                    锁定
                  </Button>
                </Popconfirm>
              )}
              {record.state === Member.State.Locked && (
                <Popconfirm
                  title="确认要解除锁定这个成员么？"
                  onConfirm={async () => {
                    await Api.member.updateOrganizationMember(userPortalStore.org?.name!, record.name, {
                      state: Member.State.Active
                    });
                    await fetchMembers();
                  }}
                >
                  <Button type="link" size="small">
                    解除锁定
                  </Button>
                </Popconfirm>
              )}
              {record.state === Member.State.Active && (
                <Button
                  onClick={() => {
                    status.memberInfoDialog.open = true;
                    status.memberInfoDialog.info = record;
                  }}
                  type="link"
                  size="small"
                >
                  编辑
                </Button>
              )}
            </div>
          )
        );
      }
    }
  ];

  const invitationJournalsColumns: ColumnProps<Invitation.AsObject>[] = [
    {
      title: '邀请人',
      dataIndex: 'inviter'
    },
    {
      title: '邀请时间',
      dataIndex: 'created_at',
      render(value, record, index) {
        return format(value);
      }
    },
    {
      title: '被邀请人',
      dataIndex: 'invitee'
    },
    {
      title: '状态',
      dataIndex: 'state',
      render(value, record, index) {
        switch (value) {
          case Invitation.State.Accepted:
            return <Tag color="blue">已接受邀请并加入单位</Tag>;
          case Invitation.State.Pending:
            return <Tag color="green">已邀请</Tag>;
          case Invitation.State.Rejected:
            return <Tag color="green">已拒绝邀请</Tag>;
        }
      }
    }
  ];

  const items = [
    {
      label: '成员列表',
      key: 'members',
      children: (
        <AtomTable
          rowKey="id"
          columns={memberColumns}
          dataSource={members.filteredData}
          bordered={false}
          pagination={{
            total: members.total,
            pageSize: members.pager.pageSize,
            showSizeChanger: true,
            onChange: async (page: number, pageSize: number) => {
              members.pager.page = page;
              members.pager.pageSize = pageSize;
              await fetchMembers();
            }
          }}
          actions={() => (
            <>
              <Input
                onChange={(e) => (members.search.name = e.target.value)}
                onPressEnter={() => searchMembers()}
                suffix={<SearchOutlined onClick={() => searchMembers()}></SearchOutlined>}
                placeholder="请输入用户名"
              ></Input>
            </>
          )}
          operations={({ selectedRows }) => (
            <>
              <Button type="primary" onClick={() => (status.invitationDialogOpen = true)}>
                成员邀请
              </Button>
              <Popconfirm
                title="确认要移除选中的成员吗？"
                onConfirm={async () => {
                  await forEach(selectedRows, async (row) => {
                    await Api.member.updateOrganizationMember(userPortalStore.org?.name!, row.name, {
                      state: Member.State.Left
                    });
                  });
                  await fetchMembers();
                }}
              >
                <Button
                  disabled={
                    selectedRows.length && selectedRows.length < userPortalStore.org.members.length ? false : true
                  }
                >
                  移除
                </Button>
              </Popconfirm>
            </>
          )}
          loading={members.loading}
          onReload={() => {
            fetchMembers();
          }}
        ></AtomTable>
      )
    },
    {
      label: '邀请记录',
      key: 'invitationJournals',
      children: (
        <AtomTable
          rowKey="id"
          columns={invitationJournalsColumns}
          dataSource={invitationJournals.data}
          bordered={false}
          pagination={{
            total: invitationJournals.total,
            pageSize: invitationJournals.pager.pageSize,
            showSizeChanger: true,
            onChange: async (page: number, pageSize: number) => {
              invitationJournals.pager.page = page;
              invitationJournals.pager.pageSize = pageSize;
              await fetchInvitationJournals();
            }
          }}
          loading={invitationJournals.loading}
          onReload={() => {
            fetchInvitationJournals();
          }}
        ></AtomTable>
      )
    }
  ];

  return (
    <PageHeader
      style={{ backgroundColor: '#fff', marginTop: 50, padding: 0, height: 'calc(100% - 50px)' }}
      className="atom-shadow"
    >
      <Tabs type="card" items={items} className="atom-tabs" style={{ marginTop: -60 }}></Tabs>

      <InvitationDialog
        onCancel={() => (status.invitationDialogOpen = false)}
        open={status.invitationDialogOpen}
        onSubmit={async (values) => {
          await Api.invitation.createInvitation({
            invitee: values.member,
            organization: userPortalStore.org.name
          });
          await fetchMembers();
          status.invitationDialogOpen = false;
        }}
      ></InvitationDialog>

      <MemberInfoDialog
        open={status.memberInfoDialog.open}
        onCancel={() => (status.memberInfoDialog.open = false)}
        values={
          status.memberInfoDialog.info && {
            groups: status.memberInfoDialog.info?.user_groups?.map((v) => v.id),
            isManager: userPortalStore.org?.managers?.includes(status.memberInfoDialog.info.name)
          }
        }
        onSubmit={async (values) => {
          Api.organization.updateMemberGroups(userPortalStore.user.tenant_id, status.memberInfoDialog.info!.id, {
            user_groups: values.groups
          });

          Api.organization.updateOrganization(userPortalStore.org?.name!, {
            managers: values.isManager
              ? union([...userPortalStore.org?.managers!, status.memberInfoDialog.info!.name])
              : pull(userPortalStore.org?.managers!, status.memberInfoDialog.info!.name)
          });

          status.memberInfoDialog.open = false;
          await fetchOrganization();
          await fetchMembers();
        }}
      ></MemberInfoDialog>
    </PageHeader>
  );
});

export default OrgMember;
