import Api from 'api/Api';
import grpcClient from 'api/grpc/grpcClient';
import * as _ from 'lodash';

export function buildObject<T>(obj: T, self: (obj: T) => void): T {
  self.call(obj, obj);
  return obj;
}
export async function initGRPC() {
  try {
    const appConfig = await Api.config.getAppConfig();
    const configResult = (await appConfig.json()) as {
      env: string;
      grpc_endpoint: string;
      oauth_login_url: string;
    };
    grpcClient.setEndpoint(configResult.grpc_endpoint);
  } catch (e) {
    console.log('initGRPC client error', e);
  }
}

declare var ENABLE_GRPC_DEBUG;
// @ts-ignore
window.ENABLE_GRPC_DEBUG = true;

interface ToObjectFunc {
  toObject: () => any;
}

interface DebugObject {
  req: ToObjectFunc;
  resp: ToObjectFunc;
}

export function debugGRPC(key: string = 'default: ', obj: DebugObject) {
  try {
    if (ENABLE_GRPC_DEBUG) {
      console.log(`=== ${key} ===`);
      console.log({
        req: checkGRPCObject(obj, 'req') ? obj.req.toObject() : '',
        resp: checkGRPCObject(obj, 'resp') ? obj.resp.toObject() : ''
      });
      console.log('');
    }
  } catch (e) {
    console.error(e);
  }
}

function checkGRPCObject(obj, keyName: string) {
  return !_.isEmpty(obj[keyName]) && typeof obj[keyName].toObject === 'function';
}

// https://github.com/sindresorhus/array-move/blob/main/index.js
const arrayMoveMutate = (array, from, to) => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

export const arrayMove = (array: any[], from: number, to: number) => {
  array = [...array];
  arrayMoveMutate(array, from, to);
  return array;
};
