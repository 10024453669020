import { BillingServicePromiseClient } from 'atom-proto/web/atom/v2/billing_grpc_web_pb';
import grpcClient from './grpcClient';
import {
  ListBillingsReq,
  ListBillingsRes,
  CreateBillingReq,
  Billing,
  GetBillingReq,
  UpdateBillingReq,
  RemoveBillingReq
} from 'atom-proto/web/atom/v2/billing_pb';
import { debugGRPC } from '../../utils/grpcUtil';
const getBillClient = () => {
  return new BillingServicePromiseClient(grpcClient.getEndpoint(), null, {
    withCredentials: true
  });
};

const billingGrpc = {
  listBillings: async (req: ListBillingsReq): Promise<ListBillingsRes> => {
    let resp;
    try {
      resp = await getBillClient().listBillings(req, grpcClient.metaInfo);
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      debugGRPC('listBillings', { req, resp });
    }
  },
  createBilling: async (req: CreateBillingReq): Promise<Billing> => {
    let resp;
    try {
      resp = await getBillClient().createBilling(req, grpcClient.metaInfo);
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      debugGRPC('createBilling', { req, resp });
    }
  },
  getBilling: async (req: GetBillingReq): Promise<Billing> => {
    let resp;
    try {
      resp = await getBillClient().getBilling(req, grpcClient.metaInfo);
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      debugGRPC('getBilling', { req, resp });
    }
  },
  updateBilling: async (req: UpdateBillingReq): Promise<Billing> => {
    let resp;
    try {
      resp = await getBillClient().updateBilling(req, grpcClient.metaInfo);
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      debugGRPC('updateBilling', { req, resp });
    }
  },
  removeBilling: async (req: RemoveBillingReq): Promise<any> => {
    let resp;
    try {
      resp = await getBillClient().removeBilling(req, grpcClient.metaInfo);
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      debugGRPC('removeBilling', { req, resp });
    }
  }
};

export default billingGrpc;
