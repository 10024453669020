import React from 'react';
import { useEffect } from 'react';
import { Card, PageHeader, Form, Input, Button, Radio, message, Select, Tabs, Table, Popconfirm } from 'antd';
import Api from 'api/Api';
import { useStore } from 'utils/stateStore';
import { Member } from 'types/member';
import { Organization } from 'types/organization';
import { useNavigate, useParams } from 'react-router';
import AtomTable from 'components/Table';
import { ColumnProps } from 'antd/lib/table';
import { ListProductsReq, Product } from 'atom-proto/web/atom/v2/product_pb';
import Pager from 'src/stores/pager';
import EditTagDialog from 'components/EditTagDialog';
import ResourceInfoDialog from 'components/ResourceInfoDialog';
import grpc from 'api/grpc/grpc';
import { buildObject } from 'utils/grpcUtil';
import { ListInstancesReq } from 'atom-proto/web/atom/v2/instance_pb';
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
};

class Status {
  resourceList: Product[] = [];
  pager = new Pager();
  search = {
    name: ''
  };
  loading = false;
  editTagDialog = {
    open: false,
    resource: [] as string[]
  };
  resourceInfoDialog = {
    open: false,
    resource: {} as Product.AsObject
  };
  name: string = '';
  tenantid: string = '';
  managers: string[] = [];
  uscid: string = '';
  contact: string = '';
  isLoading = false;
  type = undefined;
  telephone: string = '';
  address: string = '';
  members: Member.AsObject[] = [];
}

export const UpdateOrg: React.FC = (props) => {
  const { status } = useStore(new Status());
  const params = useParams();
  const navigate = useNavigate();

  const columns: ColumnProps<Product>[] = [
    {
      title: '服务器型号',
      width: 320,
      dataIndex: 'name',
      render(value, record) {
        return record.getName();
      }
    },
    {
      title: 'CPU',
      dataIndex: 'resourcesMap.cpu',
      render(value, record) {
        return record.getResourcesMap().get('cpu');
      }
    },
    {
      title: '内存',
      dataIndex: 'resourcesMap.memory',
      render(value, record) {
        return `${record.getResourcesMap().get('memory')}`;
      }
    },
    {
      title: 'GPU',
      dataIndex: 'resourcesMap.gpu',
      render(value, record) {
        return record.getResourcesMap().get('gpu') || '-';
      }
    },
    {
      title: 'GPU数量',
      dataIndex: 'resourcesMap.gpu_number',
      render(value, record) {
        return record.getResourcesMap().get('gpuCount') || '-';
      }
    },
    {
      title: '硬盘',
      dataIndex: 'resourcesMap.storage_size',
      render(value, record) {
        return `${record.getResourcesMap().get('disk')}`;
      }
    },
    {
      title: '标签',
      render(value) {
        return '工作台, 训练, 评估, 推理';
      }
    },
    {
      title: '配置',
      width: 160,
      align: 'center',
      render: (value, record) => {
        return (
          <div>
            <Button
              type="link"
              size="small"
              disabled
              onClick={() => {
                status.resourceInfoDialog.resource = record.toObject();
                status.resourceInfoDialog.open = true;
              }}
            >
              修改
            </Button>
            <Popconfirm disabled title="确认要删除选中的用户组吗？" onConfirm={async () => {}}>
              <Button disabled type="link">
                释放
              </Button>
            </Popconfirm>
          </div>
        );
      }
    }
  ];
  const storageColumns: ColumnProps<Product.AsObject>[] = [
    {
      title: '存储类型',
      width: 280,
      align: 'center',
      dataIndex: 'storage_type'
    },
    {
      title: '说明',
      align: 'center',
      dataIndex: 'description'
    },
    {
      title: '容量',
      align: 'center',
      dataIndex: 'storage_size',
      render(value) {
        return `${value}G`;
      }
    },
    {
      title: '配置',
      width: 160,
      align: 'center',
      render: (value, record) => {
        return (
          <div>
            <Button
              type="link"
              size="small"
              onClick={() => {
                status.resourceInfoDialog.resource = record;
                status.resourceInfoDialog.open = true;
              }}
            >
              修改
            </Button>
            <Popconfirm title="确认要删除选中的用户组吗？" onConfirm={async () => {}}>
              <Button type="link">释放</Button>
            </Popconfirm>
          </div>
        );
      }
    }
  ];
  const fetchOrg = async () => {
    const org_name = params.org_name;
    const getOrg = await Api.organization.getOrganization(org_name);
    status.name = getOrg.name;
    status.tenantid = getOrg.id;
    status.type = getOrg.type;
    status.managers = getOrg.managers;
    status.contact = getOrg.contact;
    status.telephone = getOrg.telephone;
    status.address = getOrg.address;
    status.uscid = getOrg.uscid;
  };
  const fetchMembers = async () => {
    const org_name = params.org_name;
    const listMembersRes = await Api.member.listOrganizationMembers(org_name, {
      state: [Member.State.Active]
    });
    status.members = listMembersRes;
  };
  useEffect(() => {
    fetchOrg();
    fetchMembers();
  }, []);
  useEffect(() => {
    if (status.tenantid) {
      fetchResources();
    }
  }, [status.tenantid]);
  const updateOrg = async () => {
    status.isLoading = true;
    const reg_tel = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/;
    if (!!status.telephone && !reg_tel.test(status.telephone)) {
      status.isLoading = false;
      return message.error('电话号码不符合规范');
    }
    const reg_socialCode = /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/;
    if (!!status.uscid && !reg_socialCode.test(status.uscid)) {
      status.isLoading = false;
      return message.error('统一社会信用代码不符合规范');
    }
    await Api.organization.updateOrganization(params.org_name, {
      managers: status.managers,
      type: status.type,
      contact: status.contact,
      telephone: status.telephone,
      address: status.address,
      uscid: status.uscid
    });
    status.isLoading = false;
    navigate(-1);
  };

  const onRadioChange = (e) => {
    status.type = e.target.value;
  };
  const fetchResources = async () => {
    const listInstancesRes = await grpc.instance.listInstances(
      buildObject(new ListInstancesReq(), (req) => {
        req.setAlltenants(true);
      })
    );
    const instance = listInstancesRes
      .toObject()
      .instancesList.filter((instance) => instance.tenantid === status.tenantid);

    if (instance.length > 0) {
      const resources = await grpc.prodcuct.listProducts(
        buildObject(new ListProductsReq(), (req) => {
          req.setProductidList(instance.map((i) => i.productid));
        })
      );
      status.resourceList = resources.getProductsList();
    }
  };
  const items = [
    {
      label: '基础信息',
      key: 'info',
      children: (
        <Form {...layout}>
          <Form.Item label={<span className="label-required">单位名称</span>}>
            <Input disabled value={status.name} />
          </Form.Item>
          <Form.Item label={<span className="label-required">客户类型</span>}>
            <Radio.Group onChange={onRadioChange} value={status.type}>
              <Radio value={Organization.Type.Government}>政府用户</Radio>
              <Radio value={Organization.Type.Institution}>企业用户</Radio>
              <Radio value={Organization.Type.Personal}>个人用户</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={<span className="label-required">管理员</span>}>
            <Select
              mode="multiple"
              allowClear
              value={status.managers}
              onChange={(value) => {
                status.managers = value;
              }}
            >
              {status.members.map((i) => (
                <Select.Option value={i.name} key={i.name}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="联系人">
            <Input
              placeholder="请输入联系人"
              value={status.contact}
              onChange={(event) => {
                status.contact = event.target.value;
              }}
            />
          </Form.Item>
          <Form.Item label="联系电话">
            <Input
              placeholder="请输入联系电话"
              value={status.telephone}
              onChange={(event) => {
                status.telephone = event.target.value;
              }}
            />
          </Form.Item>
          <Form.Item label="通讯地址">
            <Input
              placeholder="请输入通讯地址"
              value={status.address}
              onChange={(event) => {
                status.address = event.target.value;
              }}
            />
          </Form.Item>
          <Form.Item label="统一社会信用代码">
            <Input
              placeholder="请输入统一社会信用代码"
              value={status.uscid}
              onChange={(event) => {
                status.uscid = event.target.value;
              }}
            />
          </Form.Item>
          <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 22 }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                loading={status.isLoading}
                style={{ marginLeft: 10 }}
                disabled={!status.name || !status.type || !status.managers.length}
                onClick={updateOrg}
              >
                更新
              </Button>
            </div>
          </Form.Item>
        </Form>
      )
    },
    {
      label: '资源配置',
      key: 'detail',
      children: (
        <div>
          <Button
            onClick={() => {
              status.resourceInfoDialog.open = true;
              status.resourceInfoDialog.resource = undefined;
            }}
            disabled
            type="primary"
            style={{ borderRadius: 4, marginBottom: 20 }}
          >
            添加资源
          </Button>
          <div style={{ marginBottom: 20, padding: 10, backgroundColor: 'rgb(245, 247, 251)' }}>
            <div>
              <AtomTable
                rowKey={(record: Product) => record.toObject().id?.hex}
                columns={columns}
                dataSource={status.resourceList}
                bordered={false}
                pagination={false}
                loading={status.loading}
                onReload={() => fetchResources()}
                hiddenRefreshButton={true}
                operations={({ selectedRows }) => (
                  <>
                    <span style={{ fontSize: 14 }}>私有算力资源</span>
                    {/* <Button
                              disabled={!selectedRows.length}
                              onClick={() => {
                                status.editTagDialog.resource = selectedRows.map((row) => row.id)
                                status.editTagDialog.open = true
                              }}
                              type="link"
                            >
                              批量修改标签
                            </Button>
                            <Popconfirm
                              disabled={!selectedRows.length}
                              title="确认要释放选中的资源吗？"
                              onConfirm={async () => {}}
                            >
                              <Button
                                disabled={!selectedRows.length}
                                onClick={() => {
                                  status.editTagDialog.resource = selectedRows.map((row) => row.id)
                                }}
                                type="link"
                              >
                                批量释放资源
                              </Button>
                            </Popconfirm> */}
                  </>
                )}
              ></AtomTable>
            </div>
          </div>
        </div>
      )
    }
  ];

  return (
    <PageHeader
      title="基础信息"
      onBack={() => {
        navigate(-1);
      }}
    >
      <Tabs type="card" items={items} className="atom-tabs"></Tabs>
      <EditTagDialog
        onCancel={() => (status.editTagDialog.open = false)}
        open={status.editTagDialog.open}
        onSubmit={async (values) => {
          await fetchResources();
          status.editTagDialog.open = false;
        }}
      ></EditTagDialog>
      <ResourceInfoDialog
        onCancel={() => (status.resourceInfoDialog.open = false)}
        open={status.resourceInfoDialog.open}
        resource={status.resourceInfoDialog.resource}
        onSubmit={async (values) => {
          await fetchResources();
          status.resourceInfoDialog.open = false;
        }}
      ></ResourceInfoDialog>
    </PageHeader>
  );
};
