import { BillingStatus } from 'atom-proto/web/atom/v2/billing_pb';
import { Unit } from 'node_modules/atom-proto/web/atom/v2/pricing_pb';
import { Organization } from 'types/organization';
export enum Permission {
  RoleDel = '删除角色',
  OrgApp = '单位业务授权',
  OrgApply = '单位审批',
  OrgSearch = '单位查询',
  OrgList = '单位申请列表',
  RoleAdd = '新增角色',
  OrgAdd = '新建单位',
  Login = '模拟登录',
  UserFree = '用户冻结',
  UserAdd = '用户新增',
  UserSearch = '用户查询',
  UserEdit = '用户编辑',
  OrgEdit = '编辑单位信息',
  RoleSearch = '角色查询',
  RoleEdit = '角色编辑'
}

export enum Role {
  UserAdmin = 'user-admin',
  OrganizationAdmin = 'organization-admin',
  ImpersonationAdmin = 'impersonation-admin'
}

export enum TranRole {
  'user-admin' = '用户管理',
  'organization-admin' = '单位管理',
  'impersonation-admin' = '模拟登录'
}

export enum AppName {
  'DataOcean' = '数据管理',
  // 'Hubble' = '数据管理',
  'LabelX' = '数据标注',
  'ModelShop' = '模型管理',
  'TestStand' = '服务管理',
  La = '数据标注',
  Hubble = '数据管理',
  Ti = '模型训练',
  Mo = '模型管理',
  Idp = '服务管理'
}

export const BlllingStatus = {
  [BillingStatus.STATUSUNBILLED]: '未出账',
  [BillingStatus.STATUSBILLED]: '已出账',
  [BillingStatus.STATUSPAID]: '已支付',
  [BillingStatus.STATUSCANCELLED]: '已取消',
  [BillingStatus.STATUSUNKNOWN]: '未知'
};

export const ResourceTypeMap = {
  cs: '云服务器',
  gc: '通用型算力',
  as: '应用服务部署',
  os: '对象存储',
  cr: '镜像仓库',
  ts: '训练存储'
};

export const ResourceUnit = {
  [Unit.UNIT_UNKNOWN]: ['未知', '未知'],
  [Unit.UNIT_HOUR_GENERAL_ONE]: ['小时', ' /小时'],
  [Unit.UNIT_HOUR_STORAGE_GB]: ['小时', ' /GB/小时'],
  [Unit.UNIT_HOUR_MEMORY_GB]: ['小时', ' /GB/小时'],
  [Unit.UNIT_HOUR_NETWORK_GB]: ['小时', ' /GB/小时'],
  [Unit.UNIT_DAY_GENERAL_ONE]: ['天', ' /天'],
  [Unit.UNIT_DAY_STORAGE_GB]: ['天', ' /GB/天'],
  [Unit.UNIT_DAY_MEMORY_GB]: ['天', ' /GB/天'],
  [Unit.UNIT_DAY_NETWORK_GB]: ['天', ' /GB/天']
};

export const OrganizationState = {
  [Organization.State.Active]: '正常',
  [Organization.State.Freezed]: '冻结',
  [Organization.State.Applying]: '待审',
  [Organization.State.Locked]: '锁定',
  [Organization.State.Rejected]: '拒绝',
  [Organization.State.Revoked]: '撤回'
};
