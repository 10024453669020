// source: atom/v2/instance.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var github_com_alta_protopatch_patch_go_pb = require('../../github.com/alta/protopatch/patch/go_pb.js');
goog.object.extend(proto, github_com_alta_protopatch_patch_go_pb);
var github_com_envoyproxy_protoc$gen$validate_validate_validate_pb = require('../../github.com/envoyproxy/protoc-gen-validate/validate/validate_pb.js');
goog.object.extend(proto, github_com_envoyproxy_protoc$gen$validate_validate_validate_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var meta_meta_pb = require('../../meta/meta_pb.js');
goog.object.extend(proto, meta_meta_pb);
goog.exportSymbol('proto.atom.v2.CreateInstanceReq', null, global);
goog.exportSymbol('proto.atom.v2.GetInstanceReq', null, global);
goog.exportSymbol('proto.atom.v2.Instance', null, global);
goog.exportSymbol('proto.atom.v2.InstanceStatus', null, global);
goog.exportSymbol('proto.atom.v2.ListInstancesReq', null, global);
goog.exportSymbol('proto.atom.v2.ListInstancesRes', null, global);
goog.exportSymbol('proto.atom.v2.RemoveInstanceReq', null, global);
goog.exportSymbol('proto.atom.v2.UpdateInstanceReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.Instance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.Instance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.Instance.displayName = 'proto.atom.v2.Instance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.CreateInstanceReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.CreateInstanceReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.CreateInstanceReq.displayName = 'proto.atom.v2.CreateInstanceReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.GetInstanceReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.GetInstanceReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.GetInstanceReq.displayName = 'proto.atom.v2.GetInstanceReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.ListInstancesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.atom.v2.ListInstancesReq.repeatedFields_, null);
};
goog.inherits(proto.atom.v2.ListInstancesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.ListInstancesReq.displayName = 'proto.atom.v2.ListInstancesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.ListInstancesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.atom.v2.ListInstancesRes.repeatedFields_, null);
};
goog.inherits(proto.atom.v2.ListInstancesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.ListInstancesRes.displayName = 'proto.atom.v2.ListInstancesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.UpdateInstanceReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.UpdateInstanceReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.UpdateInstanceReq.displayName = 'proto.atom.v2.UpdateInstanceReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.RemoveInstanceReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.RemoveInstanceReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.RemoveInstanceReq.displayName = 'proto.atom.v2.RemoveInstanceReq';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.Instance.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.Instance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.Instance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.Instance.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && meta_meta_pb.ID.toObject(includeInstance, f),
    tenantid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    instanceid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    type: jspb.Message.getFieldWithDefault(msg, 6, ""),
    referenceid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    zone: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.Instance}
 */
proto.atom.v2.Instance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.Instance;
  return proto.atom.v2.Instance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.Instance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.Instance}
 */
proto.atom.v2.Instance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceid(value);
      break;
    case 8:
      var value = /** @type {!proto.atom.v2.InstanceStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setZone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.Instance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.Instance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.Instance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.Instance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstanceid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getReferenceid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getZone();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional meta.ID id = 1;
 * @return {?proto.meta.ID}
 */
proto.atom.v2.Instance.prototype.getId = function() {
  return /** @type{?proto.meta.ID} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.ID, 1));
};


/**
 * @param {?proto.meta.ID|undefined} value
 * @return {!proto.atom.v2.Instance} returns this
*/
proto.atom.v2.Instance.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.Instance} returns this
 */
proto.atom.v2.Instance.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.Instance.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string tenantID = 2;
 * @return {string}
 */
proto.atom.v2.Instance.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.Instance} returns this
 */
proto.atom.v2.Instance.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string instanceID = 3;
 * @return {string}
 */
proto.atom.v2.Instance.prototype.getInstanceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.Instance} returns this
 */
proto.atom.v2.Instance.prototype.setInstanceid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string productID = 4;
 * @return {string}
 */
proto.atom.v2.Instance.prototype.getProductid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.Instance} returns this
 */
proto.atom.v2.Instance.prototype.setProductid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.atom.v2.Instance.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.Instance} returns this
 */
proto.atom.v2.Instance.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string type = 6;
 * @return {string}
 */
proto.atom.v2.Instance.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.Instance} returns this
 */
proto.atom.v2.Instance.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string referenceID = 7;
 * @return {string}
 */
proto.atom.v2.Instance.prototype.getReferenceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.Instance} returns this
 */
proto.atom.v2.Instance.prototype.setReferenceid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional InstanceStatus status = 8;
 * @return {!proto.atom.v2.InstanceStatus}
 */
proto.atom.v2.Instance.prototype.getStatus = function() {
  return /** @type {!proto.atom.v2.InstanceStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.atom.v2.InstanceStatus} value
 * @return {!proto.atom.v2.Instance} returns this
 */
proto.atom.v2.Instance.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.atom.v2.Instance.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.atom.v2.Instance} returns this
*/
proto.atom.v2.Instance.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.Instance} returns this
 */
proto.atom.v2.Instance.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.Instance.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.atom.v2.Instance.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.atom.v2.Instance} returns this
*/
proto.atom.v2.Instance.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.Instance} returns this
 */
proto.atom.v2.Instance.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.Instance.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string zone = 11;
 * @return {string}
 */
proto.atom.v2.Instance.prototype.getZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.Instance} returns this
 */
proto.atom.v2.Instance.prototype.setZone = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.CreateInstanceReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.CreateInstanceReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.CreateInstanceReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.CreateInstanceReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    instance: (f = msg.getInstance()) && proto.atom.v2.Instance.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.CreateInstanceReq}
 */
proto.atom.v2.CreateInstanceReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.CreateInstanceReq;
  return proto.atom.v2.CreateInstanceReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.CreateInstanceReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.CreateInstanceReq}
 */
proto.atom.v2.CreateInstanceReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.atom.v2.Instance;
      reader.readMessage(value,proto.atom.v2.Instance.deserializeBinaryFromReader);
      msg.setInstance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.CreateInstanceReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.CreateInstanceReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.CreateInstanceReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.CreateInstanceReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstance();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.atom.v2.Instance.serializeBinaryToWriter
    );
  }
};


/**
 * optional Instance instance = 1;
 * @return {?proto.atom.v2.Instance}
 */
proto.atom.v2.CreateInstanceReq.prototype.getInstance = function() {
  return /** @type{?proto.atom.v2.Instance} */ (
    jspb.Message.getWrapperField(this, proto.atom.v2.Instance, 1));
};


/**
 * @param {?proto.atom.v2.Instance|undefined} value
 * @return {!proto.atom.v2.CreateInstanceReq} returns this
*/
proto.atom.v2.CreateInstanceReq.prototype.setInstance = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.CreateInstanceReq} returns this
 */
proto.atom.v2.CreateInstanceReq.prototype.clearInstance = function() {
  return this.setInstance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.CreateInstanceReq.prototype.hasInstance = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.GetInstanceReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.GetInstanceReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.GetInstanceReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.GetInstanceReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    instanceid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.GetInstanceReq}
 */
proto.atom.v2.GetInstanceReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.GetInstanceReq;
  return proto.atom.v2.GetInstanceReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.GetInstanceReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.GetInstanceReq}
 */
proto.atom.v2.GetInstanceReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.GetInstanceReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.GetInstanceReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.GetInstanceReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.GetInstanceReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstanceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string instanceID = 1;
 * @return {string}
 */
proto.atom.v2.GetInstanceReq.prototype.getInstanceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.GetInstanceReq} returns this
 */
proto.atom.v2.GetInstanceReq.prototype.setInstanceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.atom.v2.ListInstancesReq.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.ListInstancesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.ListInstancesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.ListInstancesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListInstancesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    productidList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    statusList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    zoneList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    alltenants: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    pager: (f = msg.getPager()) && meta_meta_pb.PagerReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.ListInstancesReq}
 */
proto.atom.v2.ListInstancesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.ListInstancesReq;
  return proto.atom.v2.ListInstancesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.ListInstancesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.ListInstancesReq}
 */
proto.atom.v2.ListInstancesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductid(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.atom.v2.InstanceStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatus(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addZone(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlltenants(value);
      break;
    case 9:
      var value = new meta_meta_pb.PagerReq;
      reader.readMessage(value,meta_meta_pb.PagerReq.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.ListInstancesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.ListInstancesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.ListInstancesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListInstancesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductidList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getZoneList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getAlltenants();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      meta_meta_pb.PagerReq.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string productID = 1;
 * @return {!Array<string>}
 */
proto.atom.v2.ListInstancesReq.prototype.getProductidList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.atom.v2.ListInstancesReq} returns this
 */
proto.atom.v2.ListInstancesReq.prototype.setProductidList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.atom.v2.ListInstancesReq} returns this
 */
proto.atom.v2.ListInstancesReq.prototype.addProductid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.atom.v2.ListInstancesReq} returns this
 */
proto.atom.v2.ListInstancesReq.prototype.clearProductidList = function() {
  return this.setProductidList([]);
};


/**
 * repeated InstanceStatus status = 2;
 * @return {!Array<!proto.atom.v2.InstanceStatus>}
 */
proto.atom.v2.ListInstancesReq.prototype.getStatusList = function() {
  return /** @type {!Array<!proto.atom.v2.InstanceStatus>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.atom.v2.InstanceStatus>} value
 * @return {!proto.atom.v2.ListInstancesReq} returns this
 */
proto.atom.v2.ListInstancesReq.prototype.setStatusList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.atom.v2.InstanceStatus} value
 * @param {number=} opt_index
 * @return {!proto.atom.v2.ListInstancesReq} returns this
 */
proto.atom.v2.ListInstancesReq.prototype.addStatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.atom.v2.ListInstancesReq} returns this
 */
proto.atom.v2.ListInstancesReq.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * repeated string zone = 3;
 * @return {!Array<string>}
 */
proto.atom.v2.ListInstancesReq.prototype.getZoneList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.atom.v2.ListInstancesReq} returns this
 */
proto.atom.v2.ListInstancesReq.prototype.setZoneList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.atom.v2.ListInstancesReq} returns this
 */
proto.atom.v2.ListInstancesReq.prototype.addZone = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.atom.v2.ListInstancesReq} returns this
 */
proto.atom.v2.ListInstancesReq.prototype.clearZoneList = function() {
  return this.setZoneList([]);
};


/**
 * optional bool allTenants = 4;
 * @return {boolean}
 */
proto.atom.v2.ListInstancesReq.prototype.getAlltenants = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.atom.v2.ListInstancesReq} returns this
 */
proto.atom.v2.ListInstancesReq.prototype.setAlltenants = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional meta.PagerReq pager = 9;
 * @return {?proto.meta.PagerReq}
 */
proto.atom.v2.ListInstancesReq.prototype.getPager = function() {
  return /** @type{?proto.meta.PagerReq} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerReq, 9));
};


/**
 * @param {?proto.meta.PagerReq|undefined} value
 * @return {!proto.atom.v2.ListInstancesReq} returns this
*/
proto.atom.v2.ListInstancesReq.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.ListInstancesReq} returns this
 */
proto.atom.v2.ListInstancesReq.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.ListInstancesReq.prototype.hasPager = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.atom.v2.ListInstancesRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.ListInstancesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.ListInstancesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.ListInstancesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListInstancesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    instancesList: jspb.Message.toObjectList(msg.getInstancesList(),
    proto.atom.v2.Instance.toObject, includeInstance),
    pager: (f = msg.getPager()) && meta_meta_pb.PagerRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.ListInstancesRes}
 */
proto.atom.v2.ListInstancesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.ListInstancesRes;
  return proto.atom.v2.ListInstancesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.ListInstancesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.ListInstancesRes}
 */
proto.atom.v2.ListInstancesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.atom.v2.Instance;
      reader.readMessage(value,proto.atom.v2.Instance.deserializeBinaryFromReader);
      msg.addInstances(value);
      break;
    case 2:
      var value = new meta_meta_pb.PagerRes;
      reader.readMessage(value,meta_meta_pb.PagerRes.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.ListInstancesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.ListInstancesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.ListInstancesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListInstancesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.atom.v2.Instance.serializeBinaryToWriter
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      meta_meta_pb.PagerRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Instance instances = 1;
 * @return {!Array<!proto.atom.v2.Instance>}
 */
proto.atom.v2.ListInstancesRes.prototype.getInstancesList = function() {
  return /** @type{!Array<!proto.atom.v2.Instance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.atom.v2.Instance, 1));
};


/**
 * @param {!Array<!proto.atom.v2.Instance>} value
 * @return {!proto.atom.v2.ListInstancesRes} returns this
*/
proto.atom.v2.ListInstancesRes.prototype.setInstancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.atom.v2.Instance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.atom.v2.Instance}
 */
proto.atom.v2.ListInstancesRes.prototype.addInstances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.atom.v2.Instance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.atom.v2.ListInstancesRes} returns this
 */
proto.atom.v2.ListInstancesRes.prototype.clearInstancesList = function() {
  return this.setInstancesList([]);
};


/**
 * optional meta.PagerRes pager = 2;
 * @return {?proto.meta.PagerRes}
 */
proto.atom.v2.ListInstancesRes.prototype.getPager = function() {
  return /** @type{?proto.meta.PagerRes} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerRes, 2));
};


/**
 * @param {?proto.meta.PagerRes|undefined} value
 * @return {!proto.atom.v2.ListInstancesRes} returns this
*/
proto.atom.v2.ListInstancesRes.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.ListInstancesRes} returns this
 */
proto.atom.v2.ListInstancesRes.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.ListInstancesRes.prototype.hasPager = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.UpdateInstanceReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.UpdateInstanceReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.UpdateInstanceReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.UpdateInstanceReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    instance: (f = msg.getInstance()) && proto.atom.v2.Instance.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.UpdateInstanceReq}
 */
proto.atom.v2.UpdateInstanceReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.UpdateInstanceReq;
  return proto.atom.v2.UpdateInstanceReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.UpdateInstanceReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.UpdateInstanceReq}
 */
proto.atom.v2.UpdateInstanceReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.atom.v2.Instance;
      reader.readMessage(value,proto.atom.v2.Instance.deserializeBinaryFromReader);
      msg.setInstance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.UpdateInstanceReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.UpdateInstanceReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.UpdateInstanceReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.UpdateInstanceReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstance();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.atom.v2.Instance.serializeBinaryToWriter
    );
  }
};


/**
 * optional Instance instance = 1;
 * @return {?proto.atom.v2.Instance}
 */
proto.atom.v2.UpdateInstanceReq.prototype.getInstance = function() {
  return /** @type{?proto.atom.v2.Instance} */ (
    jspb.Message.getWrapperField(this, proto.atom.v2.Instance, 1));
};


/**
 * @param {?proto.atom.v2.Instance|undefined} value
 * @return {!proto.atom.v2.UpdateInstanceReq} returns this
*/
proto.atom.v2.UpdateInstanceReq.prototype.setInstance = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.UpdateInstanceReq} returns this
 */
proto.atom.v2.UpdateInstanceReq.prototype.clearInstance = function() {
  return this.setInstance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.UpdateInstanceReq.prototype.hasInstance = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.RemoveInstanceReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.RemoveInstanceReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.RemoveInstanceReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.RemoveInstanceReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    instanceid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.RemoveInstanceReq}
 */
proto.atom.v2.RemoveInstanceReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.RemoveInstanceReq;
  return proto.atom.v2.RemoveInstanceReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.RemoveInstanceReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.RemoveInstanceReq}
 */
proto.atom.v2.RemoveInstanceReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.RemoveInstanceReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.RemoveInstanceReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.RemoveInstanceReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.RemoveInstanceReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstanceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string instanceID = 1;
 * @return {string}
 */
proto.atom.v2.RemoveInstanceReq.prototype.getInstanceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.RemoveInstanceReq} returns this
 */
proto.atom.v2.RemoveInstanceReq.prototype.setInstanceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.atom.v2.InstanceStatus = {
  INSTANCE_STATUS_UNKNOWN: 0,
  INSTANCE_STATUS_RUNNING: 1,
  INSTANCE_STATUS_STOPPED: 2,
  INSTANCE_STATUS_DELETED: 3
};

goog.object.extend(exports, proto.atom.v2);
