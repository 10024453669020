import * as React from 'react';
import { Button, Input } from 'antd';

interface Props {
  defaultValue: string;
  onQuery: (queryValue: string) => any;
  onReset: () => any;
}

interface State {
  tmpQuery: string;
}

export class TableFilter extends React.Component<Props, State> {
  state: State = {
    tmpQuery: ''
  };

  componentDidMount(): void {
    const { defaultValue } = this.props;
    if (defaultValue) {
      this.setState({
        tmpQuery: defaultValue
      });
    }
  }

  render() {
    const { onQuery, onReset } = this.props;
    const { tmpQuery } = this.state;

    return (
      <div style={{ padding: '8px' }}>
        <Input
          size="small"
          value={tmpQuery}
          onChange={(event) => {
            this.setState({ tmpQuery: event.target.value });
          }}
          onPressEnter={() => {
            onQuery(this.state.tmpQuery);
          }}
        />
        <div
          style={{
            margin: '4px auto 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            type="primary"
            size="small"
            style={{ marginRight: '10px' }}
            onClick={() => {
              onQuery(this.state.tmpQuery);
            }}
          >
            搜索
          </Button>
          <Button
            size="small"
            onClick={() => {
              this.setState({ tmpQuery: '' }, () => {
                onReset();
              });
            }}
          >
            重置
          </Button>
        </div>
      </div>
    );
  }
}
