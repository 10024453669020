import { InputNumber } from 'antd';
import { getNormalizedPrice, MICRO_UNIT_SCALE } from 'utils/priceUtil';

const BillingMoneyInput: React.FC<{ price: number; onChangePrice: (v: number | null) => void }> = ({
  price,
  onChangePrice
}) => {
  return (
    <InputNumber
      value={price}
      style={{ width: '100%' }}
      formatter={(value) => {
        return `${getNormalizedPrice(value)}`.toLowerCase();
      }}
      parser={(value) => value * MICRO_UNIT_SCALE}
      onChange={(v) => {
        onChangePrice(v);
      }}
    />
  );
};

export default BillingMoneyInput;
