import * as React from 'react';
import { observer } from 'mobx-react';
import * as echarts from 'echarts';
import { makeAutoObservable } from 'mobx';
import { useStore } from 'utils/stateStore';
import { useState, useEffect } from 'react';
import { BaseChart } from './BaseChart';
import { EChartsOption } from 'echarts';
import { Empty } from 'antd';
import { formatPrice } from 'utils/priceUtil';

class Status {
  constructor() {
    makeAutoObservable(this);
  }
}

export const BarChart = (props: { data: { value: {}[]; name: string }[]; xAxisData: any[]; noData: boolean }) => {
  const [option, setOption] = useState<EChartsOption>({});
  useEffect(() => {
    if (props.data.length === 0 || props.xAxisData.length === 0) {
      setOption({});
      return;
    }
    const newOption: EChartsOption = {
      xAxis: {
        type: 'category',
        data: props.xAxisData,
        axisLabel: {
          interval: 1
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          interval: 0
        }
      },
      yAxis: {
        type: 'value'
      },
      grid: {
        containLabel: true,
        left: 20,
        width: '96%'
      },
      series: props.data.map((seriesItem) => ({
        data: seriesItem.value,
        name: seriesItem.name,
        stack: 'total',
        emphasis: {
          focus: 'series'
        },
        type: 'bar'
      })),
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: function (params: any) {
          if (params.length > 1) {
            let tooltipText = params[0].name + '<br/>';
            params.forEach((item: any) => {
              tooltipText += item.marker + item.seriesName + ': ' + item.value.toLocaleString() + '<br/>';
            });
            return tooltipText;
          }
          return `<div><span style="margin-right: 100px">${
            params[0].name
          }账单</span><span>${params[0].value.toLocaleString()}</span></div>`;
        }
      }
    };
    setOption(newOption);
  }, [props.data, props.xAxisData]);
  return (
    <div>
      {props.noData ? (
        <div style={{ width: '100%', height: 400 }}>
          <Empty
            style={{
              float: 'left',
              position: 'relative',
              top: '50%',
              left: ' 50%',
              transform: 'translateX(-50%) translateY(-50%)',
              color: '#00000040'
            }}
            image={require('../../assets/no-data.png')}
            description={'暂无内容'}
          />
        </div>
      ) : (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
          <BaseChart width={'78vw'} height={400} option={option} />
        </div>
      )}
    </div>
  );
};
