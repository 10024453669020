import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, PageHeader, Popconfirm, Row, Table, Modal } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import Api from 'api/Api';
import async, { forEach } from 'async';
import EditGroupDialog from 'components/EditGroupDialog';
import MemberDialog from 'components/MemberDialog';
import AtomTable, { useAtomTableContext } from 'components/Table';
import { makeAutoObservable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import Pager from 'src/stores/pager';

import { ListProductsReq, Product } from 'atom-proto/web/atom/v2/product_pb';
import EditTagDialog from 'components/EditTagDialog';
import grpc from 'api/grpc/grpc';
import { buildObject } from 'utils/grpcUtil';
import { ListInstancesReq } from 'atom-proto/web/atom/v2/instance_pb';

class Status {
  resourceList: Product[] = [];
  pager = new Pager();
  loading = false;
  resourceDialog = {
    open: false,
    resource: [] as string[]
  };

  constructor() {
    makeAutoObservable(this);
  }
}

const status = new Status();

const OrgResource = observer(() => {
  const columns: ColumnProps<Product>[] = [
    {
      title: '服务器型号',
      width: 320,
      dataIndex: 'name',
      render(value, record) {
        return record.getName();
      }
    },
    {
      title: 'CPU',
      dataIndex: 'resourcesMap.cpu',
      render(value, record) {
        return record.getResourcesMap().get('cpu');
      }
    },
    {
      title: '内存',
      dataIndex: 'resourcesMap.memory',
      render(value, record) {
        return `${record.getResourcesMap().get('memory')}`;
      }
    },
    {
      title: 'GPU',
      dataIndex: 'resourcesMap.gpu',
      render(value, record) {
        return record.getResourcesMap().get('gpu') || '-';
      }
    },
    {
      title: 'GPU数量',
      dataIndex: 'resourcesMap.gpu_number',
      render(value, record) {
        return record.getResourcesMap().get('gpuCount') || '-';
      }
    },
    {
      title: '硬盘',
      dataIndex: 'resourcesMap.storage_size',
      render(value, record) {
        return `${record.getResourcesMap().get('disk')}`;
      }
    },
    {
      title: '标签',
      render(value) {
        return '工作台, 训练, 评估, 推理';
      }
    }
  ];
  const storageColumns: ColumnProps<Product.AsObject>[] = [
    {
      title: '存储类型',
      width: 280,
      align: 'center',
      dataIndex: 'storage_type'
    },
    {
      title: '说明',
      align: 'center',
      dataIndex: 'description'
    },
    {
      title: '容量',
      align: 'center',
      dataIndex: 'storage_size',
      render(value) {
        return `${value}G`;
      }
    }
  ];

  const fetchResources = async () => {
    status.loading = true;
    const instanceRes = await grpc.instance.listInstances(
      buildObject(new ListInstancesReq(), (req) => {
        req.setAlltenants(false);
      })
    );
    const productList = instanceRes.toObject().instancesList.map((instance) => instance.productid);
    if (productList.length > 0) {
      const listProductsRes = await grpc.prodcuct.listProducts(
        buildObject(new ListProductsReq(), (req) => {
          req.setProductidList(productList);
        })
      );
      status.resourceList = listProductsRes.getProductsList();
    }

    status.loading = false;
  };

  useEffect(() => {
    fetchResources();
  }, []);

  return (
    <PageHeader style={{ backgroundColor: '#fff' }}>
      <AtomTable
        rowKey={(record: Product) => record.toObject().id?.hex}
        columns={columns}
        dataSource={status.resourceList}
        bordered={false}
        pagination={false}
        loading={status.loading}
        onReload={() => fetchResources()}
        hiddenRefreshButton={true}
        operations={({ selectedRows }) => (
          <>
            <span style={{ fontSize: 14 }}>私有算力资源</span>
            {/* <Button
                        disabled={!selectedRows.length}
                        onClick={() => {
                          status.resourceDialog.resource = selectedRows.map((row) => row.id);
                          status.resourceDialog.open = true;
                        }}
                        style={{ fontSize: 12, lineHeight: 12 }}
                        type="link"
                      >
                        修改资源标签
                      </Button> */}
          </>
        )}
      ></AtomTable>

      <EditTagDialog
        onCancel={() => (status.resourceDialog.open = false)}
        open={status.resourceDialog.open}
        onSubmit={async (values) => {
          await fetchResources();
          status.resourceDialog.open = false;
        }}
      ></EditTagDialog>
    </PageHeader>
  );
});

export default OrgResource;
