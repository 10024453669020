import moment from 'moment';
import * as _ from 'lodash';

export const format = (date: Date | string | number) => {
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
};

export const formatMonth = (date: Date | string | number) => {
  return moment(date).format('YYYY-MM');
};

export function formatTime(t: moment.MomentInput) {
  if (_.isNil(t)) {
    return '-';
  }
  return moment(t).format('YYYY-MM-DD HH:mm:ss');
}

export function formatTimeToMillisecond(t: moment.MomentInput) {
  if (_.isNil(t)) {
    return '-';
  }
  return moment(t).format('YYYY-MM-DD HH:mm:ss.SSS');
}
