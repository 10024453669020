import * as React from 'react';
import { observer } from 'mobx-react';
import * as echarts from 'echarts';
import { makeAutoObservable, toJS } from 'mobx';
import { useStore } from 'utils/stateStore';
import { useState, useEffect } from 'react';
import { Empty } from 'antd';

class Status {
  constructor() {
    makeAutoObservable(this);
  }
}

export const BaseChart = (props: {
  option: echarts.EChartsOption;
  height: string | number;
  width: string | number;
}) => {
  const { status } = useStore(new Status());
  const [echartsInstance, setEchartsInstance] = useState<echarts.ECharts>();
  const chartRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      const instance = echarts.init(chartRef.current);
      setEchartsInstance(instance);

      return () => {
        instance.dispose();
      };
    }
  }, []);

  useEffect(() => {
    if (echartsInstance) {
      echartsInstance.setOption(toJS(props.option));
    }
  }, [echartsInstance, props.option]);

  useEffect(() => {
    const handleResize = () => {
      echartsInstance?.resize();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [echartsInstance]);
  return <div ref={chartRef} style={{ width: props.width, height: props.height || '100%' }}></div>;
};
