import { SearchOutlined } from '@ant-design/icons';
import { Button, PageHeader, Popconfirm, Tabs, Tag, Table, Select, Spin } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { makeAutoObservable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Pager from 'src/stores/pager';
import { Billing, BillingItemDetail, BillingStatus, ListBillingsReq } from 'atom-proto/web/atom/v2/billing_pb';
import { BarChart } from 'components/charts/BarChart';
import { PieChart } from 'components/charts/PieChart';
import { BillStatusTag } from 'components/BillingStatusTag';
import { TableFilter } from 'components/TableFilter';
import { BlllingStatus, ResourceTypeMap, ResourceUnit } from 'utils/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import grpc from 'api/grpc/grpc';
import { buildObject } from 'utils/grpcUtil';
import { PagerReq } from 'atom-proto/web/meta/meta_pb';
import { formatPrice, getNormalizedPrice } from 'utils/priceUtil';
import { formatMonth, formatTime } from 'utils/timeUtil';
import { ListProductsReq } from 'atom-proto/web/atom/v2/product_pb';
import { formatBillingDetail, formatChartData, BillingChartData } from 'utils/billingUtil';
class Status {
  filterName = '';
  billing = {
    data: [] as Billing.AsObject[],
    filtedData: [] as Billing.AsObject[],
    total: 0,
    page_size: 24,
    page: 0,
    search: {
      name: ''
    },
    hasMore: true,
    loading: false
  };
  barBillingList: BillingChartData[] = [];
  selectedBill = {} as Billing.AsObject;

  constructor() {
    makeAutoObservable(this);
  }
}

const status = new Status();

const OrgBilling = observer(() => {
  const { billing } = status;
  billing.page = 0;
  const [activeKey, setActiveKey] = useState('billing');
  const fetchBills = async () => {
    billing.loading = true;
    const listBillingsRes = await grpc.billing.listBillings(
      buildObject(new ListBillingsReq(), (req) => {
        req.setAlltenants(false);
        req.setPager(
          buildObject(new PagerReq(), (pager) => {
            pager.setPage(billing.page);
            pager.setLimit(billing.page_size);
          })
        );
      })
    );
    billing.page++;
    const listProductsRes = await grpc.prodcuct.listProducts(
      buildObject(new ListProductsReq(), (req) => {
        req.setProductidList([]);
      })
    );
    const resourceList = listProductsRes.toObject().productsList;
    const billingList = listBillingsRes.toObject().billingsList;
    const billings = formatBillingDetail(billingList, resourceList);

    billing.data = billings.sort((a, b) => {
      return b.billingmonth.seconds - a.billingmonth.seconds;
    });
    billing.filtedData = billing.data;

    status.selectedBill = billing.data?.[0];
    if (billings.length === 0) {
      billing.loading = false;
      return;
    }
    status.barBillingList = formatChartData(
      billings?.sort((a, b) => {
        return a.billingmonth.seconds - b.billingmonth.seconds;
      })
    );
    billing.loading = false;
  };

  const loadMore = async () => {
    billing.loading = true;
    const listBillingsRes = await grpc.billing.listBillings(
      buildObject(new ListBillingsReq(), (req) => {
        req.setAlltenants(false);
        req.setPager(
          buildObject(new PagerReq(), (pager) => {
            pager.setPage(billing.page);
            pager.setLimit(billing.page_size);
          })
        );
      })
    );
    billing.page++;
    const billList = listBillingsRes.toObject().billingsList.sort((a, b) => {
      return b.billingmonth.seconds - a.billingmonth.seconds;
    });
    if (billList.length === 0) {
      billing.hasMore = false;
      return;
    }
    billing.data = [...billing.data, ...billList];
    billing.filtedData = billing.data;
  };
  const overviewColumns: ColumnProps<Billing.AsObject>[] = [
    {
      title: '账单周期',
      width: 120,
      align: 'center',
      dataIndex: 'billingmonth',
      filterDropdown: () => {
        return (
          <TableFilter
            defaultValue={status.filterName}
            onQuery={(queryValue) => {
              billing.filtedData = billing.data.filter((item) => {
                const date = new Date(item?.billingmonth?.seconds * 1000);
                const month = formatMonth(date);
                return month.indexOf(queryValue) > -1;
              });
            }}
            onReset={() => {
              billing.filtedData = billing.data.filter((item) => {
                const date = new Date(item?.billingmonth?.seconds * 1000);
                const month = formatMonth(date);
                return month.indexOf('') > -1;
              });
            }}
          />
        );
      },
      filterIcon: (filtered) => <SearchOutlined />,
      render(value, reocerd) {
        const date = new Date(value?.seconds * 1000);
        return (
          <Button
            onClick={() => {
              status.selectedBill = reocerd;
              setActiveKey('detail');
            }}
            type="link"
          >
            {formatMonth(date)}
          </Button>
        );
      }
    },
    {
      title: '状态',
      width: 120,
      align: 'center',
      dataIndex: 'status',
      filters: (() => {
        return Object.getOwnPropertyNames(BlllingStatus).map((key) => {
          return { text: BlllingStatus[key], value: key };
        });
      })(),
      onFilter: (value, billing: Billing.AsObject) => {
        return billing.status == value;
      },
      render(value) {
        return <BillStatusTag status={value} />;
      }
    },
    {
      title: '目录总价',
      dataIndex: 'total',
      align: 'center',
      ellipsis: true,
      width: 120,
      render(value) {
        return formatPrice(value, '¥');
      }
    },
    {
      title: '折扣优惠',
      dataIndex: 'discount',
      align: 'center',
      ellipsis: true,
      width: 120,
      render(value) {
        return formatPrice(value, '¥');
      }
    },
    {
      title: '应付金额',
      ellipsis: true,
      align: 'center',
      width: 120,
      render(value, record) {
        const paid = record.total - record.discount;
        return formatPrice(paid, '¥');
      }
    },
    {
      title: '已支付金额',
      ellipsis: true,
      dataIndex: 'paid',
      align: 'center',
      width: 120,
      render(value) {
        return formatPrice(value, '¥');
      }
    }
  ];
  useEffect(() => {
    fetchBills();
  }, []);
  const detailColumns: ColumnProps<BillingItemDetail.AsObject>[] = [
    {
      title: '计费项目',
      dataIndex: 'productname'
    },
    {
      title: '费用类型',
      align: 'center',
      dataIndex: 'resourcetype',
      render(value) {
        return ResourceTypeMap[value] || '-';
      }
    },
    {
      title: '计费起始日期',
      align: 'center',
      dataIndex: 'startdate',
      render(value) {
        return formatTime(moment(value?.seconds * 1000));
      }
    },
    {
      title: '计费终止日期',
      align: 'center',
      dataIndex: 'enddate',
      render(value) {
        return formatTime(moment(value?.seconds * 1000));
      }
    },
    {
      title: '量',
      align: 'center',
      dataIndex: 'usageamount',
      render(value, record) {
        return value + (ResourceUnit?.[record.unit]?.[0] || '');
      }
    },
    {
      title: '单价',
      align: 'center',
      dataIndex: 'unitprice',
      render(value, record) {
        return formatPrice(value, '¥') + (ResourceUnit?.[record.unit]?.[1] || '');
      }
    },
    {
      title: '应付金额',
      align: 'right',
      render(value, record) {
        return formatPrice(record.cost, '¥');
      }
    }
  ];
  const getChartData = (data: BillingItemDetail.AsObject[]) => {
    const resourceCostMap: { [key: string]: number } = {};

    data?.forEach((item) => {
      const { resourcetype, cost } = item;

      if (resourceCostMap[resourcetype]) {
        resourceCostMap[resourcetype] += cost;
      } else {
        resourceCostMap[resourcetype] = cost;
      }
    });

    return Object.entries(resourceCostMap).map(([resourcetype, cost]) => ({
      name: ResourceTypeMap[resourcetype],
      value: getNormalizedPrice(cost)
    }));
  };

  const items = [
    {
      label: '账单总览',
      key: 'billing',
      children: (
        <div>
          <div>费用趋势</div>
          <BarChart
            key="overview"
            noData={!status.barBillingList || status.barBillingList.length === 0}
            xAxisData={toJS(status.barBillingList)?.map((item) => item.date)}
            data={[{ value: status.barBillingList?.map((item) => getNormalizedPrice(item.total)), name: 'total' }]}
          />
          <div>历史账单</div>
          <div>
            <InfiniteScroll
              dataLength={billing.filtedData.length}
              next={() => {
                billing.page += 1;
                loadMore();
              }}
              hasMore={billing.hasMore}
              loader={null}
              scrollableTarget="mainContent"
            >
              <Table<Billing.AsObject>
                rowKey={(record: Billing.AsObject) => record?.id?.hex}
                columns={overviewColumns}
                dataSource={billing.filtedData}
                style={{ marginTop: 20, overflow: 'auto' }}
                pagination={false}
              />
            </InfiniteScroll>
          </div>
        </div>
      )
    },
    {
      label: '账单明细',
      key: 'detail',
      children: (
        <div>
          <Select
            showSearch
            onSelect={(id) => {
              status.selectedBill = billing.data.find((billing) => billing.id?.hex === id);
            }}
            value={status.selectedBill?.id?.hex}
            style={{ width: 200, margin: '20px 0' }}
            filterOption={(input, option) => {
              return (option?.children ?? '').toLowerCase().includes(input.toLowerCase());
            }}
          >
            {billing.data?.map((billing) => {
              const date = new Date(billing?.billingmonth?.seconds * 1000);
              return (
                <Select.Option key={billing.id?.hex} value={billing.id?.hex}>
                  {formatMonth(date)}
                </Select.Option>
              );
            })}
          </Select>
          <div>费用分布</div>
          <PieChart
            noData={!status.barBillingList || status.barBillingList.length === 0}
            key="detail"
            data={getChartData(status.selectedBill?.detailsList)}
            total={getNormalizedPrice(status.selectedBill?.total)}
          />
          <div>费用明细</div>
          <div style={{ padding: '0 20px' }}>
            <Table<BillingItemDetail.AsObject>
              rowKey={(record: BillingItemDetail.AsObject) => record?.productid}
              columns={detailColumns}
              dataSource={status.selectedBill?.detailsList}
              style={{ marginTop: 20 }}
              pagination={false}
              footer={() => {
                return (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>合计：</span>
                    <span>
                      {formatPrice(status.selectedBill?.detailsList?.reduce((acc, curr) => acc + curr.cost, 0), '¥')}
                    </span>
                  </div>
                );
              }}
            />
          </div>
        </div>
      )
    }
  ];

  return (
    <PageHeader
      style={{ backgroundColor: '#fff', marginTop: 50, padding: 0, height: 'calc(100% - 50px)' }}
      className="atom-shadow"
    >
      <Tabs
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key);
          status.selectedBill = billing?.data[0];
        }}
        type="card"
        items={items}
        className="atom-tabs"
        style={{ marginTop: -60 }}
      ></Tabs>
    </PageHeader>
  );
});

export default OrgBilling;
