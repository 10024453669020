import { Form, Input, Modal, ModalProps, Checkbox } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect } from 'react';

const EditTagDialog: React.FC<ModalProps & { onSubmit(values: any): void }> = ({ onOk, onSubmit, ...modalProps }) => {
  const [form] = useForm();
  const plainOptions = [
    { label: '自动标注', value: '1' },
    { label: '工作台', value: '2' },
    { label: '训练', value: '3' },
    { label: '评估', value: '4' },
    { label: '推理', value: '5' }
  ];
  useEffect(() => {
    if (!modalProps.open) {
      form.resetFields();
    }
  }, [modalProps.open]);
  return (
    <Modal
      width="40vw"
      bodyStyle={{
        maxHeight: '70vh',
        overflowY: 'auto'
      }}
      title="修改资源标签"
      maskClosable={false}
      onOk={async () => {
        try {
          const values = await form.validateFields();
          onSubmit(values);
        } catch (err) {}
      }}
      {...modalProps}
    >
      <Form style={{ margin: '50px', padding: 'auto' }} form={form}>
        <Form.Item label="标签" name={'tag'}>
          <Checkbox.Group options={plainOptions} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditTagDialog;
