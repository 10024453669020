/**
 * @fileoverview gRPC-Web generated client stub for atom.v2
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var github_com_alta_protopatch_patch_go_pb = require('../../github.com/alta/protopatch/patch/go_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var meta_meta_pb = require('../../meta/meta_pb.js')

var atom_v2_pricing_pb = require('../../atom/v2/pricing_pb.js')
const proto = {};
proto.atom = {};
proto.atom.v2 = require('./billing_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.atom.v2.BillingServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.atom.v2.BillingServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.ListBillingsReq,
 *   !proto.atom.v2.ListBillingsRes>}
 */
const methodDescriptor_BillingService_ListBillings = new grpc.web.MethodDescriptor(
  '/atom.v2.BillingService/ListBillings',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.ListBillingsReq,
  proto.atom.v2.ListBillingsRes,
  /**
   * @param {!proto.atom.v2.ListBillingsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.ListBillingsRes.deserializeBinary
);


/**
 * @param {!proto.atom.v2.ListBillingsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.ListBillingsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.ListBillingsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.BillingServiceClient.prototype.listBillings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.BillingService/ListBillings',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListBillings,
      callback);
};


/**
 * @param {!proto.atom.v2.ListBillingsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.ListBillingsRes>}
 *     Promise that resolves to the response
 */
proto.atom.v2.BillingServicePromiseClient.prototype.listBillings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.BillingService/ListBillings',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListBillings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.GetBillingReq,
 *   !proto.atom.v2.Billing>}
 */
const methodDescriptor_BillingService_GetBilling = new grpc.web.MethodDescriptor(
  '/atom.v2.BillingService/GetBilling',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.GetBillingReq,
  proto.atom.v2.Billing,
  /**
   * @param {!proto.atom.v2.GetBillingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.Billing.deserializeBinary
);


/**
 * @param {!proto.atom.v2.GetBillingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.Billing)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.Billing>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.BillingServiceClient.prototype.getBilling =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.BillingService/GetBilling',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetBilling,
      callback);
};


/**
 * @param {!proto.atom.v2.GetBillingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.Billing>}
 *     Promise that resolves to the response
 */
proto.atom.v2.BillingServicePromiseClient.prototype.getBilling =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.BillingService/GetBilling',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetBilling);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.CreateBillingReq,
 *   !proto.atom.v2.Billing>}
 */
const methodDescriptor_BillingService_CreateBilling = new grpc.web.MethodDescriptor(
  '/atom.v2.BillingService/CreateBilling',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.CreateBillingReq,
  proto.atom.v2.Billing,
  /**
   * @param {!proto.atom.v2.CreateBillingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.Billing.deserializeBinary
);


/**
 * @param {!proto.atom.v2.CreateBillingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.Billing)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.Billing>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.BillingServiceClient.prototype.createBilling =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.BillingService/CreateBilling',
      request,
      metadata || {},
      methodDescriptor_BillingService_CreateBilling,
      callback);
};


/**
 * @param {!proto.atom.v2.CreateBillingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.Billing>}
 *     Promise that resolves to the response
 */
proto.atom.v2.BillingServicePromiseClient.prototype.createBilling =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.BillingService/CreateBilling',
      request,
      metadata || {},
      methodDescriptor_BillingService_CreateBilling);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.UpdateBillingReq,
 *   !proto.atom.v2.Billing>}
 */
const methodDescriptor_BillingService_UpdateBilling = new grpc.web.MethodDescriptor(
  '/atom.v2.BillingService/UpdateBilling',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.UpdateBillingReq,
  proto.atom.v2.Billing,
  /**
   * @param {!proto.atom.v2.UpdateBillingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.Billing.deserializeBinary
);


/**
 * @param {!proto.atom.v2.UpdateBillingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.Billing)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.Billing>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.BillingServiceClient.prototype.updateBilling =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.BillingService/UpdateBilling',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdateBilling,
      callback);
};


/**
 * @param {!proto.atom.v2.UpdateBillingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.Billing>}
 *     Promise that resolves to the response
 */
proto.atom.v2.BillingServicePromiseClient.prototype.updateBilling =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.BillingService/UpdateBilling',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdateBilling);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.RemoveBillingReq,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_BillingService_RemoveBilling = new grpc.web.MethodDescriptor(
  '/atom.v2.BillingService/RemoveBilling',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.RemoveBillingReq,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.atom.v2.RemoveBillingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.atom.v2.RemoveBillingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.BillingServiceClient.prototype.removeBilling =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.BillingService/RemoveBilling',
      request,
      metadata || {},
      methodDescriptor_BillingService_RemoveBilling,
      callback);
};


/**
 * @param {!proto.atom.v2.RemoveBillingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.atom.v2.BillingServicePromiseClient.prototype.removeBilling =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.BillingService/RemoveBilling',
      request,
      metadata || {},
      methodDescriptor_BillingService_RemoveBilling);
};


module.exports = proto.atom.v2;

