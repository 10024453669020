import { Modal, ModalProps, Transfer, Table } from 'antd';
import type { TransferDirection } from 'antd/es/transfer';
import React, { useEffect, useState } from 'react';
import { PieChart } from './charts/PieChart';
import { Billing, BillingItemDetail } from 'atom-proto/web/atom/v2/billing_pb';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { formatTime } from 'utils/timeUtil';
import { formatPrice, getNormalizedPrice } from 'utils/priceUtil';
import { ResourceTypeMap, ResourceUnit } from 'utils/constants';
const BillDetailDialog: React.FC<
  ModalProps & {
    billing: Billing.AsObject;
  }
> = ({ billing, ...modalProps }) => {
  const getChartData = (data: BillingItemDetail.AsObject[]) => {
    const resourceCostMap: { [key: string]: number } = {};

    data?.forEach((item) => {
      const { resourcetype, cost } = item;

      if (resourceCostMap[resourcetype]) {
        resourceCostMap[resourcetype] += cost;
      } else {
        resourceCostMap[resourcetype] = cost;
      }
    });

    return Object.entries(resourceCostMap).map(([resourcetype, cost]) => ({
      name: ResourceTypeMap[resourcetype],
      value: getNormalizedPrice(cost)
    }));
  };

  const detailColumns: ColumnProps<BillingItemDetail.AsObject>[] = [
    {
      title: '计费项目',
      dataIndex: 'productname'
    },
    {
      title: '费用类型',
      align: 'center',
      dataIndex: 'resourcetype',
      render(value) {
        return ResourceTypeMap[value] || '-';
      }
    },
    {
      title: '计费起始日期',
      align: 'center',
      dataIndex: 'startdate',
      render(value) {
        return formatTime(moment(value?.seconds * 1000));
      }
    },
    {
      title: '计费终止日期',
      align: 'center',
      dataIndex: 'enddate',
      render(value) {
        return formatTime(moment(value?.seconds * 1000));
      }
    },
    {
      title: '量',
      align: 'center',
      dataIndex: 'usageamount',
      render(value, record) {
        return value + (ResourceUnit?.[record.unit]?.[0] || '');
      }
    },
    {
      title: '单价',
      align: 'center',
      dataIndex: 'unitprice',
      render(value, record) {
        return formatPrice(value, '¥') + (ResourceUnit?.[record.unit]?.[1] || '');
      }
    },
    {
      title: '应付金额',
      align: 'right',
      dataIndex: 'cost',
      render(value) {
        return formatPrice(value, '¥');
      }
    }
  ];
  return (
    <Modal
      width="80vw"
      bodyStyle={{
        maxHeight: '100vh',
        overflowY: 'auto'
      }}
      footer={false}
      {...modalProps}
    >
      <div>
        <div>费用分布</div>
        <PieChart
          noData={!billing?.detailsList || billing?.detailsList?.length === 0}
          key="detail"
          data={getChartData(billing?.detailsList)}
          total={getNormalizedPrice(billing?.total)}
        />
        <div>费用明细</div>
        <div style={{ padding: '0 20px' }}>
          <Table<BillingItemDetail.AsObject>
            rowKey={(record: BillingItemDetail.AsObject) => record?.productid}
            columns={detailColumns}
            dataSource={billing?.detailsList}
            style={{ marginTop: 20 }}
            pagination={false}
            footer={() => {
              return (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>合计：</span>
                  <span>{formatPrice(billing?.detailsList?.reduce((acc, curr) => acc + curr.cost, 0), '¥')}</span>
                </div>
              );
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default BillDetailDialog;
