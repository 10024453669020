import { Form, Input, Modal, ModalProps, Checkbox, Select, InputNumber, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useStore } from 'utils/stateStore';
import React, { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { BillStatusTag } from './BillingStatusTag';
import { Billing } from 'atom-proto/web/atom/v2/billing_pb';
import { BlllingStatus } from 'utils/constants';
import { formatPrice } from 'utils/priceUtil';
import BillingMoneyInput from './BillingMoneyInput';
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
class Status {
  billing: Billing.AsObject = undefined;
  disableUpdate: boolean = false;
}
const EditBillDialog: React.FC<ModalProps & { value: any; onSubmit(values: any): void }> = ({
  onOk,
  value,
  onSubmit,
  ...modalProps
}) => {
  const [form] = useForm();
  const { status } = useStore(new Status());
  useEffect(() => {
    if (!modalProps.open) {
      form.resetFields();
    }
  }, [modalProps.open]);

  useEffect(() => {
    if (value) {
      status.billing = cloneDeep(value);
      form.setFieldsValue(status.billing);
    }
  }, [modalProps.open, value]);
  return (
    <Modal
      width="40vw"
      bodyStyle={{
        maxHeight: '70vh',
        overflowY: 'auto'
      }}
      okButtonProps={{ disabled: status.disableUpdate }}
      title="账单编辑"
      maskClosable={false}
      onOk={async () => {
        try {
          onSubmit(status.billing);
        } catch (err) {}
      }}
      {...modalProps}
    >
      <Form {...layout} style={{ padding: 'auto' }} form={form}>
        <Form.Item label="已出账金额" name={'total'}>
          <div>{formatPrice(status.billing?.total, '')}</div>
        </Form.Item>
        <Form.Item label="折扣金额" name={'discount'}>
          <BillingMoneyInput
            price={status.billing?.discount}
            onChangePrice={(value) => {
              if (!value || value < 0) {
                status.billing.discount = 0;
                form.setFieldValue('discount', 0);
              } else if (value > status.billing.total) {
                message.error('折扣金额金额不能大于已出账金额');
                status.disableUpdate = true;
                return;
              } else {
                status.billing.discount = value;
              }
              status.disableUpdate = false;
            }}
          />
        </Form.Item>
        <Form.Item label="应付金额" name={'due'}>
          <div>{formatPrice(status.billing?.total - status.billing?.discount, '')}</div>
        </Form.Item>
        <Form.Item label="已支付金额" name={'paid'}>
          <BillingMoneyInput
            price={status.billing?.paid}
            onChangePrice={(value) => {
              if (!value || value < 0) {
                status.billing.paid = 0;
                form.setFieldValue('paid', 0);
              } else if (value > status.billing.total - status.billing.discount) {
                message.error('已支付金额不能大于应付金额');
                status.disableUpdate = true;
                return;
              } else {
                status.billing.paid = value;
              }
              status.disableUpdate = false;
            }}
          />
        </Form.Item>
        <Form.Item label="欠款金额">
          <div>{formatPrice(status.billing?.total - status.billing?.discount - status.billing?.paid, '')}</div>
        </Form.Item>
        <Form.Item label="账单状态" name={'status'}>
          {/* <BillStatusTag status={status.billing?.status} /> */}
          <Select
            style={{ width: '40%' }}
            onChange={(value) => {
              status.billing.status = value;
            }}
          >
            {Object.getOwnPropertyNames(BlllingStatus).map((key) => {
              return (
                <Select.Option value={parseInt(key)} key={parseInt(key)}>
                  {BlllingStatus[key]}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditBillDialog;
