export class GRPCClient {
  private _idToken: string;
  private _endpoint: string;

  public get metaInfo() {
    return {
      // authorization: `id_token ${this._idToken}`
    };
  }

  public setIdToken(idToken: string) {
    this._idToken = idToken;
  }

  public setEndpoint(endpoint: string) {
    this._endpoint = endpoint;
  }

  public getEndpoint() {
    return this._endpoint;
  }
}

const grpcClient = new GRPCClient();
export default grpcClient;
