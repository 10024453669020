import { ProductServiceClient } from 'atom-proto/web/atom/v2/product_grpc_web_pb';
import grpcClient from './grpcClient';
import {
  CreateProductReq,
  GetProductReq,
  ListProductsReq,
  ListProductsRes,
  Product,
  RemoveProductReq,
  UpdateProductReq
} from 'atom-proto/web/atom/v2/product_pb';
import { CreateBillingReq } from 'atom-proto/web/atom/v2/billing_pb';
import { debugGRPC } from 'utils/grpcUtil';

const getProductClient = () => {
  return new ProductServiceClient(grpcClient.getEndpoint(), null, {
    withCredentials: true
  });
};

const productGrpc = {
  listProducts: async (req: ListProductsReq): Promise<ListProductsRes> => {
    let resp;
    try {
      resp = await new Promise((resolve, reject) => {
        getProductClient().listProducts(req, grpcClient.metaInfo, (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      });
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      debugGRPC('listProducts', { req, resp });
    }
  },
  createProduct: async (req: CreateProductReq): Promise<Product> => {
    let resp;
    try {
      resp = await new Promise((resolve, reject) => {
        getProductClient().createProduct(req, grpcClient.metaInfo, (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      });
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      debugGRPC('createProduct', { req, resp });
    }
  },
  getProduct: async (req: GetProductReq): Promise<Product> => {
    let resp;
    try {
      resp = await new Promise((resolve, reject) => {
        getProductClient().getProduct(req, grpcClient.metaInfo, (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      });
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      debugGRPC('getProduct', { req, resp });
    }
  },
  updateProduct: async (req: UpdateProductReq): Promise<Product> => {
    let resp;
    try {
      resp = await new Promise((resolve, reject) => {
        getProductClient().updateProduct(req, grpcClient.metaInfo, (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      });
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      debugGRPC('updateProduct', { req, resp });
    }
  },
  removeBilling: async (req: RemoveProductReq): Promise<any> => {
    let resp;
    try {
      resp = await new Promise((resolve, reject) => {
        getProductClient().removeProduct(req, grpcClient.metaInfo, (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      });
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      debugGRPC('removeBilling', { req, resp });
    }
  }
};

export default productGrpc;
