import * as React from 'react';
import { observer } from 'mobx-react';
import * as echarts from 'echarts';
import { makeAutoObservable, toJS } from 'mobx';
import { useStore } from 'utils/stateStore';
import { useState, useEffect } from 'react';
import { EChartsOption } from 'echarts';
import { BaseChart } from './BaseChart';
import { Empty } from 'antd';

class Status {
  constructor() {
    makeAutoObservable(this);
  }
}

export const PieChart = (props: { data: { name: string; value: number }[]; total: number; noData: boolean }) => {
  const [option, setOption] = useState<EChartsOption>({});

  const status = new Status();

  useEffect(() => {
    const newOption: EChartsOption = {
      title: {
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      graphic: {
        elements: [
          {
            type: 'text',
            left: '90',
            top: '46%',
            style: {
              text: props.total.toLocaleString(),
              textAlign: 'center',
              fill: '#333',
              fontSize: 16,
              fontWeight: 'bold'
            }
          },
          {
            type: 'text',
            left: '95',
            top: '52%',
            style: {
              text: '费用金额',
              textAlign: 'center',
              fill: '#999',
              fontSize: 14
            }
          }
        ]
      },
      legend: {
        orient: 'vertical',
        bottom: '20%',
        right: '30',
        icon: 'circle',
        itemWidth: 6,
        itemHeight: 6,
        formatter: function (name: string) {
          const data = props.data.find((item) => item.name === name);
          const percent = ((data?.value || 0) / props.total) * 100;
          return `${name}  ${data?.value.toLocaleString()}   ${percent.toFixed(0)}%`;
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['80px', '120px'],
          center: ['140px', '50%'],
          padAngle: 2,
          data: props.data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          }
        }
      ]
    };
    setOption(newOption);
  }, [props.data, props.total]);
  return (
    <div>
      {props.noData ? (
        <div style={{ width: '100%', height: 400 }}>
          <Empty
            style={{
              float: 'left',
              position: 'relative',
              top: '50%',
              left: ' 50%',
              transform: 'translateX(-50%) translateY(-50%)',
              color: '#00000040'
            }}
            image={require('../../assets/no-data.png')}
            description={'暂无内容'}
          />
        </div>
      ) : (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
          <BaseChart width={500} height={400} option={option} />
        </div>
      )}
    </div>
  );
};
