import React from 'react'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { PageHeader, Card, Button, Table, Popconfirm, Input, Tag, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Api from 'api/Api'
import { Role, OrganizationState } from 'utils/constants'
import { useStore } from 'utils/stateStore'
import { Organization } from 'types/organization'
import { useNavigate } from 'react-router'

class Status {
  nameFilter: string = undefined
  orgs: Organization.AsObject[] = []
  total: number = undefined
  page = 1
  page_size = 10
  curOrg: Organization.AsObject = undefined
  disableSimulateLogin = true
}

const ChildTable = (props) => {
  const [data, setData] = useState([])

  const fetchAPP = async () => {
    const getAppRes = await Api.service.listOrganizationServices(props.record.name)
    setData(getAppRes)
  }
  useEffect(() => {
    fetchAPP()
  }, [])

  const childColumns: ColumnsType<Service.AsObject> = [
    { title: '业务系统', dataIndex: 'name' },
    {
      title: '系统状态',
      dataIndex: 'state',
      align: 'center',
      render(value) {
        switch (value) {
          case Service.State.Active:
            return '正常'
          case Service.State.Freezed:
            return '冻结'
          case Service.State.Locked:
            return '锁定'
          default:
            return ''
        }
      }
    },
    {
      title: '上次登录时间',
      align: 'center',
      dataIndex: 'last_login_at',
      render(value) {
        if (!value || moment(value).valueOf() === -62135596800000) {
          return ''
        }
        return moment(value).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: '操作',
      align: 'center',
      render: (value, record) => {
        return (
          <Button
            type="link"
            size="small"
            disabled={
              props.record.state !== Organization.State.Active ||
              record.state !== Service.State.Active ||
              props.disableSimulateLogin
            }
            onClick={async () => {
              window.open(
                `${record.website}/v2/login/browser?org=${props.record.name}&app=${record.name}&return_to=${record.website}/hodor_redirect`
              )
              /*const alterRes = await Api.user.simulateLogin(record.org_id)
              if (alterRes.code === 200) {
                const getRes = await Api.orgapplication.getOrgApplication(record.org_id, record.id)
                if (getRes.code === 200) {
                  window.open(`${getRes.record?.website}/hodor_redirect?id_token=${alterRes.record}`)
                }
              }*/
            }}
          >
            模拟登录
          </Button>
        )
      }
    }
  ]
  return <Table rowKey="id" columns={childColumns} dataSource={data} pagination={false} bordered />
}

export const OrgList = () => {
  const { status } = useStore(new Status())
  const navigate = useNavigate()

  const fetchOrgs = async () => {
    const listOrganizationsRes = await Api.organization.listOrganizations({
      name_pattern: status.nameFilter,
      page_number: status.page - 1,
      page_size: status.page_size,
      state: [Organization.State.Active, Organization.State.Freezed, Organization.State.Locked]
    })
    status.orgs = listOrganizationsRes.organizations
    status.total = listOrganizationsRes.page.total
  }

  useEffect(() => {
    fetchOrgs()
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo?.system_roles?.includes(Role.ImpersonationAdmin)) {
      status.disableSimulateLogin = false
    }
  }, [])

  const columns: ColumnsType<Organization.AsObject> = [
    {
      title: '单位名称',
      width: 200,
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '单位管理员',
      align: 'left',
      ellipsis: true,
      render(value, record: Organization.AsObject) {
        return (
          <Tooltip title={record.managers?.map((i) => <div>{i}</div>)}>
            {`${record.managers?.[0]}${record.managers?.length > 1 ? '...' : ''}`}{' '}
          </Tooltip>
        )
      }
    },
    {
      title: '鉴权方式',
      align: 'center',
      width: 100
    },
    {
      title: '成员数量',
      align: 'center',
      width: 100
    },
    {
      title: '状态',
      dataIndex: 'state',
      align: 'center',
      width: 100,
      filters: (() => {
        return Object.getOwnPropertyNames(OrganizationState).map((key) => {
          return { text: OrganizationState[key], value: key }
        })
      })(),
      onFilter: (value, record) => {
        return record.state == value
      },
      render(value) {
        switch (value) {
          case Organization.State.Active:
            return '正常'
          case Organization.State.Freezed:
            return '冻结'
          case Organization.State.Applying:
            return '待审'
          case Organization.State.Locked:
            return '锁定'
          case Organization.State.Rejected:
            return '拒绝'
          case Organization.State.Revoked:
            return '撤回'
          default:
            return ''
        }
      }
    },
    {
      title: '资源',
      align: 'center'
    },
    {
      title: '创建日期',
      align: 'center',
      dataIndex: 'created_at',
      render(value) {
        if (!value || moment(value).valueOf() === -62135596800000) {
          return ''
        }
        return moment(value).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: '最后登陆时间',
      align: 'center',
      dataIndex: 'last_login_at',
      render(value) {
        if (!value || moment(value).valueOf() === -62135596800000) {
          return ''
        }
        return moment(value).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: '操作',
      width: 200,
      align: 'center',
      render: (value, record) => {
        return (
          <div>
            <Button
              type="link"
              disabled={record.state === Organization.State.Freezed}
              size="small"
              onClick={() => {
                navigate(`/admin_portal/org_list/${record.name}/update`)
              }}
            >
              编辑
            </Button>
            {record.state === Organization.State.Active && (
              <Popconfirm
                title="确认要冻结这个单位么？"
                onConfirm={async () => {
                  await Api.organization.frozenOrganization(record.name)
                  await fetchOrgs()
                }}
              >
                <Button type="link" size="small">
                  冻结
                </Button>
              </Popconfirm>
            )}
            {record.state === Organization.State.Freezed && (
              <Popconfirm
                title="确认要激活这个单位么？"
                onConfirm={async () => {
                  await Api.organization.unfrozenOrganization(record.name)
                  await fetchOrgs()
                }}
              >
                <Button type="link" size="small">
                  激活
                </Button>
              </Popconfirm>
            )}
            <Button
              type="link"
              disabled={record.state === Organization.State.Freezed}
              size="small"
              onClick={() => {
                navigate(`/admin_portal/org_list/${record.id}/billing`)
              }}
            >
              账单
            </Button>
          </div>
        )
      }
    }
  ]

  return (
    <PageHeader
      title="单位列表"
      style={{
        background: '#FFF'
      }}
    >
      <Card>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              navigate('/admin_portal/org_list/create')
            }}
          >
            新建
          </Button>
          <Input.Search
            placeholder="请输入单位名"
            value={status.nameFilter}
            enterButton="搜索"
            style={{ width: 300 }}
            onChange={(ev) => {
              status.nameFilter = ev.target.value
              status.page = 1
            }}
            onSearch={fetchOrgs}
          />
        </div>
        <Table<Organization.AsObject>
          rowKey="id"
          columns={columns}
          dataSource={status.orgs}
          style={{ marginTop: 20 }}
          bordered={false}
          pagination={{
            total: status.total,
            pageSize: status.page_size,
            showSizeChanger: false,
            onChange: async (page: number, page_size: number) => {
              status.page = page
              status.page_size = page_size
              await fetchOrgs()
            }
          }}
        />
      </Card>
    </PageHeader>
  )
}
