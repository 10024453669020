/**
 * @fileoverview gRPC-Web generated client stub for atom.v2
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var github_com_alta_protopatch_patch_go_pb = require('../../github.com/alta/protopatch/patch/go_pb.js')

var github_com_envoyproxy_protoc$gen$validate_validate_validate_pb = require('../../github.com/envoyproxy/protoc-gen-validate/validate/validate_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var meta_meta_pb = require('../../meta/meta_pb.js')
const proto = {};
proto.atom = {};
proto.atom.v2 = require('./instance_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.atom.v2.InstanceServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.atom.v2.InstanceServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.ListInstancesReq,
 *   !proto.atom.v2.ListInstancesRes>}
 */
const methodDescriptor_InstanceService_ListInstances = new grpc.web.MethodDescriptor(
  '/atom.v2.InstanceService/ListInstances',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.ListInstancesReq,
  proto.atom.v2.ListInstancesRes,
  /**
   * @param {!proto.atom.v2.ListInstancesReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.ListInstancesRes.deserializeBinary
);


/**
 * @param {!proto.atom.v2.ListInstancesReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.ListInstancesRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.ListInstancesRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.InstanceServiceClient.prototype.listInstances =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.InstanceService/ListInstances',
      request,
      metadata || {},
      methodDescriptor_InstanceService_ListInstances,
      callback);
};


/**
 * @param {!proto.atom.v2.ListInstancesReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.ListInstancesRes>}
 *     Promise that resolves to the response
 */
proto.atom.v2.InstanceServicePromiseClient.prototype.listInstances =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.InstanceService/ListInstances',
      request,
      metadata || {},
      methodDescriptor_InstanceService_ListInstances);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.GetInstanceReq,
 *   !proto.atom.v2.Instance>}
 */
const methodDescriptor_InstanceService_GetInstance = new grpc.web.MethodDescriptor(
  '/atom.v2.InstanceService/GetInstance',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.GetInstanceReq,
  proto.atom.v2.Instance,
  /**
   * @param {!proto.atom.v2.GetInstanceReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.Instance.deserializeBinary
);


/**
 * @param {!proto.atom.v2.GetInstanceReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.Instance)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.Instance>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.InstanceServiceClient.prototype.getInstance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.InstanceService/GetInstance',
      request,
      metadata || {},
      methodDescriptor_InstanceService_GetInstance,
      callback);
};


/**
 * @param {!proto.atom.v2.GetInstanceReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.Instance>}
 *     Promise that resolves to the response
 */
proto.atom.v2.InstanceServicePromiseClient.prototype.getInstance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.InstanceService/GetInstance',
      request,
      metadata || {},
      methodDescriptor_InstanceService_GetInstance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.CreateInstanceReq,
 *   !proto.atom.v2.Instance>}
 */
const methodDescriptor_InstanceService_CreateInstance = new grpc.web.MethodDescriptor(
  '/atom.v2.InstanceService/CreateInstance',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.CreateInstanceReq,
  proto.atom.v2.Instance,
  /**
   * @param {!proto.atom.v2.CreateInstanceReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.Instance.deserializeBinary
);


/**
 * @param {!proto.atom.v2.CreateInstanceReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.Instance)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.Instance>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.InstanceServiceClient.prototype.createInstance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.InstanceService/CreateInstance',
      request,
      metadata || {},
      methodDescriptor_InstanceService_CreateInstance,
      callback);
};


/**
 * @param {!proto.atom.v2.CreateInstanceReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.Instance>}
 *     Promise that resolves to the response
 */
proto.atom.v2.InstanceServicePromiseClient.prototype.createInstance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.InstanceService/CreateInstance',
      request,
      metadata || {},
      methodDescriptor_InstanceService_CreateInstance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.UpdateInstanceReq,
 *   !proto.atom.v2.Instance>}
 */
const methodDescriptor_InstanceService_UpdateInstance = new grpc.web.MethodDescriptor(
  '/atom.v2.InstanceService/UpdateInstance',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.UpdateInstanceReq,
  proto.atom.v2.Instance,
  /**
   * @param {!proto.atom.v2.UpdateInstanceReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.Instance.deserializeBinary
);


/**
 * @param {!proto.atom.v2.UpdateInstanceReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.Instance)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.Instance>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.InstanceServiceClient.prototype.updateInstance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.InstanceService/UpdateInstance',
      request,
      metadata || {},
      methodDescriptor_InstanceService_UpdateInstance,
      callback);
};


/**
 * @param {!proto.atom.v2.UpdateInstanceReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.Instance>}
 *     Promise that resolves to the response
 */
proto.atom.v2.InstanceServicePromiseClient.prototype.updateInstance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.InstanceService/UpdateInstance',
      request,
      metadata || {},
      methodDescriptor_InstanceService_UpdateInstance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.RemoveInstanceReq,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_InstanceService_RemoveInstance = new grpc.web.MethodDescriptor(
  '/atom.v2.InstanceService/RemoveInstance',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.RemoveInstanceReq,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.atom.v2.RemoveInstanceReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.atom.v2.RemoveInstanceReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.InstanceServiceClient.prototype.removeInstance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.InstanceService/RemoveInstance',
      request,
      metadata || {},
      methodDescriptor_InstanceService_RemoveInstance,
      callback);
};


/**
 * @param {!proto.atom.v2.RemoveInstanceReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.atom.v2.InstanceServicePromiseClient.prototype.removeInstance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.InstanceService/RemoveInstance',
      request,
      metadata || {},
      methodDescriptor_InstanceService_RemoveInstance);
};


module.exports = proto.atom.v2;

