// source: atom/v2/pricing.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var github_com_alta_protopatch_patch_go_pb = require('../../github.com/alta/protopatch/patch/go_pb.js');
goog.object.extend(proto, github_com_alta_protopatch_patch_go_pb);
var github_com_envoyproxy_protoc$gen$validate_validate_validate_pb = require('../../github.com/envoyproxy/protoc-gen-validate/validate/validate_pb.js');
goog.object.extend(proto, github_com_envoyproxy_protoc$gen$validate_validate_validate_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var meta_meta_pb = require('../../meta/meta_pb.js');
goog.object.extend(proto, meta_meta_pb);
goog.exportSymbol('proto.atom.v2.CreatePricingReq', null, global);
goog.exportSymbol('proto.atom.v2.GetPricingReq', null, global);
goog.exportSymbol('proto.atom.v2.ListPricingsReq', null, global);
goog.exportSymbol('proto.atom.v2.ListPricingsRes', null, global);
goog.exportSymbol('proto.atom.v2.Pricing', null, global);
goog.exportSymbol('proto.atom.v2.RemovePricingReq', null, global);
goog.exportSymbol('proto.atom.v2.Unit', null, global);
goog.exportSymbol('proto.atom.v2.UpdatePricingReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.Pricing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.Pricing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.Pricing.displayName = 'proto.atom.v2.Pricing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.CreatePricingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.CreatePricingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.CreatePricingReq.displayName = 'proto.atom.v2.CreatePricingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.GetPricingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.GetPricingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.GetPricingReq.displayName = 'proto.atom.v2.GetPricingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.ListPricingsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.ListPricingsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.ListPricingsReq.displayName = 'proto.atom.v2.ListPricingsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.ListPricingsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.atom.v2.ListPricingsRes.repeatedFields_, null);
};
goog.inherits(proto.atom.v2.ListPricingsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.ListPricingsRes.displayName = 'proto.atom.v2.ListPricingsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.UpdatePricingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.UpdatePricingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.UpdatePricingReq.displayName = 'proto.atom.v2.UpdatePricingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.RemovePricingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.RemovePricingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.RemovePricingReq.displayName = 'proto.atom.v2.RemovePricingReq';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.Pricing.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.Pricing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.Pricing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.Pricing.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && meta_meta_pb.ID.toObject(includeInstance, f),
    productid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    unitprice: jspb.Message.getFieldWithDefault(msg, 3, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    zone: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.Pricing}
 */
proto.atom.v2.Pricing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.Pricing;
  return proto.atom.v2.Pricing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.Pricing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.Pricing}
 */
proto.atom.v2.Pricing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitprice(value);
      break;
    case 4:
      var value = /** @type {!proto.atom.v2.Unit} */ (reader.readEnum());
      msg.setUnit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setZone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.Pricing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.Pricing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.Pricing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.Pricing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getProductid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUnitprice();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getZone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional meta.ID id = 1;
 * @return {?proto.meta.ID}
 */
proto.atom.v2.Pricing.prototype.getId = function() {
  return /** @type{?proto.meta.ID} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.ID, 1));
};


/**
 * @param {?proto.meta.ID|undefined} value
 * @return {!proto.atom.v2.Pricing} returns this
*/
proto.atom.v2.Pricing.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.Pricing} returns this
 */
proto.atom.v2.Pricing.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.Pricing.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string productId = 2;
 * @return {string}
 */
proto.atom.v2.Pricing.prototype.getProductid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.Pricing} returns this
 */
proto.atom.v2.Pricing.prototype.setProductid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 unitPrice = 3;
 * @return {number}
 */
proto.atom.v2.Pricing.prototype.getUnitprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.atom.v2.Pricing} returns this
 */
proto.atom.v2.Pricing.prototype.setUnitprice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Unit unit = 4;
 * @return {!proto.atom.v2.Unit}
 */
proto.atom.v2.Pricing.prototype.getUnit = function() {
  return /** @type {!proto.atom.v2.Unit} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.atom.v2.Unit} value
 * @return {!proto.atom.v2.Pricing} returns this
 */
proto.atom.v2.Pricing.prototype.setUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string zone = 5;
 * @return {string}
 */
proto.atom.v2.Pricing.prototype.getZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.Pricing} returns this
 */
proto.atom.v2.Pricing.prototype.setZone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.CreatePricingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.CreatePricingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.CreatePricingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.CreatePricingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricing: (f = msg.getPricing()) && proto.atom.v2.Pricing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.CreatePricingReq}
 */
proto.atom.v2.CreatePricingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.CreatePricingReq;
  return proto.atom.v2.CreatePricingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.CreatePricingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.CreatePricingReq}
 */
proto.atom.v2.CreatePricingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.atom.v2.Pricing;
      reader.readMessage(value,proto.atom.v2.Pricing.deserializeBinaryFromReader);
      msg.setPricing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.CreatePricingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.CreatePricingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.CreatePricingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.CreatePricingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricing();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.atom.v2.Pricing.serializeBinaryToWriter
    );
  }
};


/**
 * optional Pricing pricing = 1;
 * @return {?proto.atom.v2.Pricing}
 */
proto.atom.v2.CreatePricingReq.prototype.getPricing = function() {
  return /** @type{?proto.atom.v2.Pricing} */ (
    jspb.Message.getWrapperField(this, proto.atom.v2.Pricing, 1));
};


/**
 * @param {?proto.atom.v2.Pricing|undefined} value
 * @return {!proto.atom.v2.CreatePricingReq} returns this
*/
proto.atom.v2.CreatePricingReq.prototype.setPricing = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.CreatePricingReq} returns this
 */
proto.atom.v2.CreatePricingReq.prototype.clearPricing = function() {
  return this.setPricing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.CreatePricingReq.prototype.hasPricing = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.GetPricingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.GetPricingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.GetPricingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.GetPricingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    productid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    zone: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.GetPricingReq}
 */
proto.atom.v2.GetPricingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.GetPricingReq;
  return proto.atom.v2.GetPricingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.GetPricingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.GetPricingReq}
 */
proto.atom.v2.GetPricingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setZone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.GetPricingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.GetPricingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.GetPricingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.GetPricingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getZone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string productId = 1;
 * @return {string}
 */
proto.atom.v2.GetPricingReq.prototype.getProductid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.GetPricingReq} returns this
 */
proto.atom.v2.GetPricingReq.prototype.setProductid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string zone = 2;
 * @return {string}
 */
proto.atom.v2.GetPricingReq.prototype.getZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.GetPricingReq} returns this
 */
proto.atom.v2.GetPricingReq.prototype.setZone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.ListPricingsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.ListPricingsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.ListPricingsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListPricingsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    productid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    zone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pager: (f = msg.getPager()) && meta_meta_pb.PagerReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.ListPricingsReq}
 */
proto.atom.v2.ListPricingsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.ListPricingsReq;
  return proto.atom.v2.ListPricingsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.ListPricingsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.ListPricingsReq}
 */
proto.atom.v2.ListPricingsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setZone(value);
      break;
    case 3:
      var value = new meta_meta_pb.PagerReq;
      reader.readMessage(value,meta_meta_pb.PagerReq.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.ListPricingsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.ListPricingsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.ListPricingsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListPricingsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getZone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      meta_meta_pb.PagerReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional string productId = 1;
 * @return {string}
 */
proto.atom.v2.ListPricingsReq.prototype.getProductid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.ListPricingsReq} returns this
 */
proto.atom.v2.ListPricingsReq.prototype.setProductid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string zone = 2;
 * @return {string}
 */
proto.atom.v2.ListPricingsReq.prototype.getZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.ListPricingsReq} returns this
 */
proto.atom.v2.ListPricingsReq.prototype.setZone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional meta.PagerReq pager = 3;
 * @return {?proto.meta.PagerReq}
 */
proto.atom.v2.ListPricingsReq.prototype.getPager = function() {
  return /** @type{?proto.meta.PagerReq} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerReq, 3));
};


/**
 * @param {?proto.meta.PagerReq|undefined} value
 * @return {!proto.atom.v2.ListPricingsReq} returns this
*/
proto.atom.v2.ListPricingsReq.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.ListPricingsReq} returns this
 */
proto.atom.v2.ListPricingsReq.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.ListPricingsReq.prototype.hasPager = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.atom.v2.ListPricingsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.ListPricingsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.ListPricingsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.ListPricingsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListPricingsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricingsList: jspb.Message.toObjectList(msg.getPricingsList(),
    proto.atom.v2.Pricing.toObject, includeInstance),
    pager: (f = msg.getPager()) && meta_meta_pb.PagerRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.ListPricingsRes}
 */
proto.atom.v2.ListPricingsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.ListPricingsRes;
  return proto.atom.v2.ListPricingsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.ListPricingsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.ListPricingsRes}
 */
proto.atom.v2.ListPricingsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.atom.v2.Pricing;
      reader.readMessage(value,proto.atom.v2.Pricing.deserializeBinaryFromReader);
      msg.addPricings(value);
      break;
    case 2:
      var value = new meta_meta_pb.PagerRes;
      reader.readMessage(value,meta_meta_pb.PagerRes.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.ListPricingsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.ListPricingsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.ListPricingsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListPricingsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.atom.v2.Pricing.serializeBinaryToWriter
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      meta_meta_pb.PagerRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Pricing pricings = 1;
 * @return {!Array<!proto.atom.v2.Pricing>}
 */
proto.atom.v2.ListPricingsRes.prototype.getPricingsList = function() {
  return /** @type{!Array<!proto.atom.v2.Pricing>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.atom.v2.Pricing, 1));
};


/**
 * @param {!Array<!proto.atom.v2.Pricing>} value
 * @return {!proto.atom.v2.ListPricingsRes} returns this
*/
proto.atom.v2.ListPricingsRes.prototype.setPricingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.atom.v2.Pricing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.atom.v2.Pricing}
 */
proto.atom.v2.ListPricingsRes.prototype.addPricings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.atom.v2.Pricing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.atom.v2.ListPricingsRes} returns this
 */
proto.atom.v2.ListPricingsRes.prototype.clearPricingsList = function() {
  return this.setPricingsList([]);
};


/**
 * optional meta.PagerRes pager = 2;
 * @return {?proto.meta.PagerRes}
 */
proto.atom.v2.ListPricingsRes.prototype.getPager = function() {
  return /** @type{?proto.meta.PagerRes} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerRes, 2));
};


/**
 * @param {?proto.meta.PagerRes|undefined} value
 * @return {!proto.atom.v2.ListPricingsRes} returns this
*/
proto.atom.v2.ListPricingsRes.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.ListPricingsRes} returns this
 */
proto.atom.v2.ListPricingsRes.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.ListPricingsRes.prototype.hasPager = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.UpdatePricingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.UpdatePricingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.UpdatePricingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.UpdatePricingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricing: (f = msg.getPricing()) && proto.atom.v2.Pricing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.UpdatePricingReq}
 */
proto.atom.v2.UpdatePricingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.UpdatePricingReq;
  return proto.atom.v2.UpdatePricingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.UpdatePricingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.UpdatePricingReq}
 */
proto.atom.v2.UpdatePricingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.atom.v2.Pricing;
      reader.readMessage(value,proto.atom.v2.Pricing.deserializeBinaryFromReader);
      msg.setPricing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.UpdatePricingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.UpdatePricingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.UpdatePricingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.UpdatePricingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricing();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.atom.v2.Pricing.serializeBinaryToWriter
    );
  }
};


/**
 * optional Pricing pricing = 1;
 * @return {?proto.atom.v2.Pricing}
 */
proto.atom.v2.UpdatePricingReq.prototype.getPricing = function() {
  return /** @type{?proto.atom.v2.Pricing} */ (
    jspb.Message.getWrapperField(this, proto.atom.v2.Pricing, 1));
};


/**
 * @param {?proto.atom.v2.Pricing|undefined} value
 * @return {!proto.atom.v2.UpdatePricingReq} returns this
*/
proto.atom.v2.UpdatePricingReq.prototype.setPricing = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.UpdatePricingReq} returns this
 */
proto.atom.v2.UpdatePricingReq.prototype.clearPricing = function() {
  return this.setPricing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.UpdatePricingReq.prototype.hasPricing = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.RemovePricingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.RemovePricingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.RemovePricingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.RemovePricingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    productid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    zone: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.RemovePricingReq}
 */
proto.atom.v2.RemovePricingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.RemovePricingReq;
  return proto.atom.v2.RemovePricingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.RemovePricingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.RemovePricingReq}
 */
proto.atom.v2.RemovePricingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setZone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.RemovePricingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.RemovePricingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.RemovePricingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.RemovePricingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getZone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string productId = 1;
 * @return {string}
 */
proto.atom.v2.RemovePricingReq.prototype.getProductid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.RemovePricingReq} returns this
 */
proto.atom.v2.RemovePricingReq.prototype.setProductid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string zone = 2;
 * @return {string}
 */
proto.atom.v2.RemovePricingReq.prototype.getZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.RemovePricingReq} returns this
 */
proto.atom.v2.RemovePricingReq.prototype.setZone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.atom.v2.Unit = {
  UNIT_UNKNOWN: 0,
  UNIT_HOUR_GENERAL_ONE: 1,
  UNIT_HOUR_STORAGE_GB: 2,
  UNIT_HOUR_MEMORY_GB: 3,
  UNIT_HOUR_NETWORK_GB: 4,
  UNIT_DAY_GENERAL_ONE: 101,
  UNIT_DAY_STORAGE_GB: 102,
  UNIT_DAY_MEMORY_GB: 103,
  UNIT_DAY_NETWORK_GB: 104
};

goog.object.extend(exports, proto.atom.v2);
