/**
 * @fileoverview gRPC-Web generated client stub for atom.v2
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var github_com_alta_protopatch_patch_go_pb = require('../../github.com/alta/protopatch/patch/go_pb.js')

var github_com_envoyproxy_protoc$gen$validate_validate_validate_pb = require('../../github.com/envoyproxy/protoc-gen-validate/validate/validate_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var meta_meta_pb = require('../../meta/meta_pb.js')
const proto = {};
proto.atom = {};
proto.atom.v2 = require('./product_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.atom.v2.ProductServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.atom.v2.ProductServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.ListProductsReq,
 *   !proto.atom.v2.ListProductsRes>}
 */
const methodDescriptor_ProductService_ListProducts = new grpc.web.MethodDescriptor(
  '/atom.v2.ProductService/ListProducts',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.ListProductsReq,
  proto.atom.v2.ListProductsRes,
  /**
   * @param {!proto.atom.v2.ListProductsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.ListProductsRes.deserializeBinary
);


/**
 * @param {!proto.atom.v2.ListProductsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.ListProductsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.ListProductsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.ProductServiceClient.prototype.listProducts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.ProductService/ListProducts',
      request,
      metadata || {},
      methodDescriptor_ProductService_ListProducts,
      callback);
};


/**
 * @param {!proto.atom.v2.ListProductsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.ListProductsRes>}
 *     Promise that resolves to the response
 */
proto.atom.v2.ProductServicePromiseClient.prototype.listProducts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.ProductService/ListProducts',
      request,
      metadata || {},
      methodDescriptor_ProductService_ListProducts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.GetProductReq,
 *   !proto.atom.v2.Product>}
 */
const methodDescriptor_ProductService_GetProduct = new grpc.web.MethodDescriptor(
  '/atom.v2.ProductService/GetProduct',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.GetProductReq,
  proto.atom.v2.Product,
  /**
   * @param {!proto.atom.v2.GetProductReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.Product.deserializeBinary
);


/**
 * @param {!proto.atom.v2.GetProductReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.Product)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.Product>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.ProductServiceClient.prototype.getProduct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.ProductService/GetProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_GetProduct,
      callback);
};


/**
 * @param {!proto.atom.v2.GetProductReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.Product>}
 *     Promise that resolves to the response
 */
proto.atom.v2.ProductServicePromiseClient.prototype.getProduct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.ProductService/GetProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_GetProduct);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.CreateProductReq,
 *   !proto.atom.v2.Product>}
 */
const methodDescriptor_ProductService_CreateProduct = new grpc.web.MethodDescriptor(
  '/atom.v2.ProductService/CreateProduct',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.CreateProductReq,
  proto.atom.v2.Product,
  /**
   * @param {!proto.atom.v2.CreateProductReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.Product.deserializeBinary
);


/**
 * @param {!proto.atom.v2.CreateProductReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.Product)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.Product>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.ProductServiceClient.prototype.createProduct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.ProductService/CreateProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_CreateProduct,
      callback);
};


/**
 * @param {!proto.atom.v2.CreateProductReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.Product>}
 *     Promise that resolves to the response
 */
proto.atom.v2.ProductServicePromiseClient.prototype.createProduct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.ProductService/CreateProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_CreateProduct);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.UpdateProductReq,
 *   !proto.atom.v2.Product>}
 */
const methodDescriptor_ProductService_UpdateProduct = new grpc.web.MethodDescriptor(
  '/atom.v2.ProductService/UpdateProduct',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.UpdateProductReq,
  proto.atom.v2.Product,
  /**
   * @param {!proto.atom.v2.UpdateProductReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.Product.deserializeBinary
);


/**
 * @param {!proto.atom.v2.UpdateProductReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.Product)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.Product>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.ProductServiceClient.prototype.updateProduct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.ProductService/UpdateProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_UpdateProduct,
      callback);
};


/**
 * @param {!proto.atom.v2.UpdateProductReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.Product>}
 *     Promise that resolves to the response
 */
proto.atom.v2.ProductServicePromiseClient.prototype.updateProduct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.ProductService/UpdateProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_UpdateProduct);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.RemoveProductReq,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ProductService_RemoveProduct = new grpc.web.MethodDescriptor(
  '/atom.v2.ProductService/RemoveProduct',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.RemoveProductReq,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.atom.v2.RemoveProductReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.atom.v2.RemoveProductReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.ProductServiceClient.prototype.removeProduct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.ProductService/RemoveProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_RemoveProduct,
      callback);
};


/**
 * @param {!proto.atom.v2.RemoveProductReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.atom.v2.ProductServicePromiseClient.prototype.removeProduct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.ProductService/RemoveProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_RemoveProduct);
};


module.exports = proto.atom.v2;

