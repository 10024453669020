import { Row } from 'antd';
import Api from 'api/Api';
import { IGetUserResp } from 'api/user.api';
import Login from 'components/Login';
import RequireLogin from 'components/RequireLogin';
import {
  AppIcon,
  ArrowRightIcon,
  ChartIcon,
  FileIcon,
  HIcon,
  LaIcon,
  MarketIcon,
  MoIcon,
  TiIcon
} from 'components/icons/';
import { find, map } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { Navigate } from 'react-router-dom';
import Account from 'src/pages/user-portal/Account';
import UserPortalIndex from 'src/pages/user-portal/Index';
import OrgList from 'src/pages/user-portal/OrgList';
import Welcome from 'src/pages/user-portal/Welcome';
import OrgGroup from 'src/pages/user-portal/org-manage/Group';
import OrgMember from 'src/pages/user-portal/org-manage/Member';
import OrgBilling from 'src/pages/user-portal/org-manage/Billing';
import OrgResource from 'src/pages/user-portal/org-manage/Resource';
import Chat from 'src/pages/user-portal/service/Chat';
import Detection from 'src/pages/user-portal/service/Detection';
import ServiceIndex from 'src/pages/user-portal/service/Index';
import { Application } from 'types/application';
import { Invitation } from 'types/invitation';
import { Organization } from 'types/organization';
import { User } from 'types/user';
import { UserGroup } from 'types/userGroup';
import { AppName, Role } from 'utils/constants';
import { UserPortal } from '../layouts/UserPortal/UserPortal';
import { NotFound } from '../pages/land/NotFound/NotFound';
import Org from '../pages/user-portal/Org';
import { Member } from 'types/member';

const AppMaps = {
  DataOcean: <HIcon />,
  Hubble: <HIcon />,
  Mo: <MoIcon />,
  Ti: <TiIcon />,
  La: <LaIcon></LaIcon>
};

export class UserPortalStore {
  selectedKey = '';

  openKeys: string[] = [];

  breadcrumbs: string[] = [];

  /**
   * 可访问的应用
   */
  applications: Application.AsObject[] = [];

  user: IGetUserResp & User.AsObject = {} as any;

  /**
   * 邀请记录
   */
  invitations: Invitation.AsObject[] = [];

  /**
   * 用户组
   */
  groups: UserGroup.AsObject[] = [];

  /**
   * 当前单位
   */
  org: Organization.AsObject = {} as any;

  /**
   * 所有单位
   */
  orgs: Organization.AsObject[] = [];

  /**
   * 是否单位管理员
   */
  get isManager() {
    return this.org.managers?.includes(this.user.name);
  }

  get routes() {
    const hasOrg = !!this.user.tenant_id;
    return [
      {
        path: '/',
        element: <Navigate replace to="/user_portal"></Navigate>
      },
      {
        path: '/user_portal',
        element: (
          <RequireLogin fallback={<Login></Login>}>
            <UserPortal></UserPortal>
          </RequireLogin>
        ),
        children: [
          {
            index: true,
            element: <UserPortalIndex />
          },
          ...(hasOrg
            ? [
                {
                  path: 'org',
                  name: this.user?.tenant_name,
                  element: <Org></Org>,
                  icon: <AppIcon />
                },
                {
                  path: 'org-manage',
                  name: '单位管理',
                  icon: <FileIcon />,
                  visible: this.isManager,
                  children: [
                    {
                      path: 'member',
                      name: '单位成员管理',
                      element: <OrgMember></OrgMember>
                    },
                    {
                      path: 'group',
                      name: '用户组管理',
                      element: <OrgGroup></OrgGroup>
                    },
                    {
                      path: 'resource',
                      name: '单位资源管理',
                      element: <OrgResource></OrgResource>
                    },
                    {
                      path: 'billing',
                      name: '单位账单',
                      element: <OrgBilling></OrgBilling>
                    }
                  ]
                },
                {
                  path: 'apps',
                  name: '应用',
                  icon: <ChartIcon />,
                  children: this.applications?.map((app) => {
                    const to = app.website;

                    return {
                      path: app.name,
                      name: (
                        <Row align={'middle'}>
                          {AppName[app.name as keyof typeof AppName]}
                          <ArrowRightIcon style={{ fontSize: 10, marginLeft: 10 }} />
                          {AppMaps[app.name as keyof typeof AppMaps]}
                        </Row>
                      ),
                      to,
                      target: '_blank'
                      // element: <App></App>
                    };
                  })
                }
              ]
            : []),
          {
            path: 'welcome',
            name: '欢迎',
            visible: false,
            breadcrumbVisible: false,
            element: <Welcome></Welcome>
          },
          {
            path: 'account',
            name: '账号信息',
            visible: false,
            element: <Account></Account>
          },
          {
            path: 'orgs',
            name: '隶属单位',
            visible: false,
            element: <OrgList></OrgList>
          },
          {
            path: '*',
            element: <NotFound></NotFound>
          },
          {
            path: 'service',
            name: '应用商城',
            visible: true,
            icon: <MarketIcon></MarketIcon>,
            children: [
              {
                path: 'chat',
                name: '大语言模型服务',
                element: <Chat></Chat>
              },
              {
                path: 'detection',
                name: '通用物体检测服务',
                element: <Detection></Detection>,
                visible: false
              },
              {
                path: 'classification',
                name: '通用分类模型服务',
                visible: false
              },
              {
                index: true,
                visible: false,
                element: <ServiceIndex></ServiceIndex>
              }
            ]
          }
        ]
      }
    ];
  }

  constructor() {
    makeAutoObservable(this);
  }

  fetchApplications = async () => {
    const data = await Api.application.listApplications();
    this.applications = data;
  };

  fetchOrganization = async () => {
    const data = await Api.organization.getOrganization(this.user.tenant_name);
    this.org = data;
  };

  fetchMembers = async () => {
    const members = await Api.member.listOrganizationMembers(this.user.tenant_name, {
      state: [Member.State.Active, Member.State.Locked]
    });
    this.org.members = members;
  };

  fetchUserGroups = async () => {
    const { user_groups: userGroups } = await Api.organization.listUserGroups(this.user.tenant_id);

    const data = await Api.member.getMember(this.user.tenant_name, this.user.member_id);

    if (data.user_groups) {
      // @ts-ignore
      data.user_groups = map(data.user_groups, (group: string) => {
        return find(userGroups, (v) => v.id === group);
      });
    }

    this.groups = data.user_groups;
  };

  fetchInvitations = async () => {
    const data = await Api.invitation.listInvitations({
      invitee: userPortalStore.user?.name,
      page_number: 0,
      page_size: 10000,
      state: Invitation.State.Pending
    });
    if (data.invitations) {
      this.invitations = data.invitations;
    }
  };

  fetchOrganizations = async () => {
    const data = await Api.organization.listOrganizations({
      i_am_member: true,
      page_number: 0,
      page_size: 10000
    });

    this.orgs = data.organizations;
  };
}

export const userPortalStore = new UserPortalStore();
