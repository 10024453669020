import { InstanceServiceClient } from 'atom-proto/web/atom/v2/instance_grpc_web_pb';
import grpcClient from './grpcClient';

import { debugGRPC } from '../../utils/grpcUtil';
import {
  CreateInstanceReq,
  GetInstanceReq,
  Instance,
  ListInstancesReq,
  ListInstancesRes,
  RemoveInstanceReq,
  UpdateInstanceReq
} from 'atom-proto/web/atom/v2/instance_pb';
const getInstanceClient = () => {
  return new InstanceServiceClient(grpcClient.getEndpoint(), null, {
    withCredentials: true
  });
};

const instanceGrpc = {
  listInstances: async (req: ListInstancesReq): Promise<ListInstancesRes> => {
    let resp;
    try {
      resp = await new Promise((resolve, reject) => {
        getInstanceClient().listInstances(req, grpcClient.metaInfo, (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      });
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      debugGRPC('listInstances', { req, resp });
    }
  },
  getInstance: async (req: GetInstanceReq): Promise<Instance> => {
    let resp;
    try {
      resp = await new Promise((resolve, reject) => {
        getInstanceClient().getInstance(req, grpcClient.metaInfo, (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      });
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      debugGRPC('getInstance', { req, resp });
    }
  },
  createInstance: async (req: CreateInstanceReq): Promise<Instance> => {
    let resp;
    try {
      resp = await new Promise((resolve, reject) => {
        getInstanceClient().createInstance(req, grpcClient.metaInfo, (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      });
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      debugGRPC('createInstance', { req, resp });
    }
  },
  updateInstance: async (req: UpdateInstanceReq): Promise<Instance> => {
    let resp;
    try {
      resp = await new Promise((resolve, reject) => {
        getInstanceClient().updateInstance(req, grpcClient.metaInfo, (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      });
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      debugGRPC('updateInstance', { req, resp });
    }
  },
  removeInstance: async (req: RemoveInstanceReq): Promise<any> => {
    let resp;
    try {
      resp = await new Promise((resolve, reject) => {
        getInstanceClient().removeInstance(req, grpcClient.metaInfo, (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      });
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      debugGRPC('removeInstance', { req, resp });
    }
  }
};

export default instanceGrpc;
