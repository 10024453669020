import { Form, Input, Modal, ModalProps, Checkbox, Select, InputNumber } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { Product } from 'atom-proto/web/atom/v2/product_pb';
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
};
const ResourceInfoDialog: React.FC<ModalProps & { onSubmit(values: any): void; resource: Product.AsObject }> = ({
  onOk,
  onSubmit,
  resource,
  ...modalProps
}) => {
  const [form] = useForm();
  const [currentType, setCurrentType] = useState('');
  const plainOptions = [
    { label: '自动标注', value: '1' },
    { label: '工作台', value: '2' },
    { label: '训练', value: '3' },
    { label: '评估', value: '4' },
    { label: '推理', value: '5' }
  ];

  useEffect(() => {
    if (!modalProps.open) {
      form.resetFields();
    }
  }, [modalProps.open, form]);

  useEffect(() => {
    if (modalProps.open && resource) {
      form.setFieldsValue(resource);
      setCurrentType('');
    } else if (modalProps.open && !resource) {
      form.setFieldsValue({
        type: ''
      });
      setCurrentType('');
    }
  }, [resource, modalProps.open, form]);
  useEffect(() => {
    const checkFormValues = () => {
      const newValues = form.getFieldsValue();
      setCurrentType(newValues);
    };
    // 初始检查一次表单值
    checkFormValues();
  }, [form]);
  return (
    <Modal
      width="50vw"
      bodyStyle={{
        maxHeight: '70vh',
        overflowY: 'auto'
      }}
      title={`${resource?.id ? '修改' : '添加'}资源`}
      maskClosable={false}
      onOk={async () => {
        try {
          const values = await form.validateFields();
          onSubmit(values);
        } catch (err) {}
      }}
      {...modalProps}
    >
      <Form {...layout} style={{ margin: '50px', padding: 'auto' }} form={form}>
        {/* <Form.Item label="资源地区" name={'region'} rules={[{ required: true }]}>
          <Select disabled={resource?.id} style={{ width: 200 }}>
            {Object.getOwnPropertyNames(RegionTypeMap).map((key) => {
              return (
                <Select.Option key={key} value={key}>
                  {RegionTypeMap[key]}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item> */}
        <Form.Item shouldUpdate label="资源类型" name="type" rules={[{ required: true }]}>
          {/* <Select
            onSelect={(value) => {
              console.log(value);
              setCurrentType(value);
            }}
            disabled={resource?.id}
            style={{ width: 200 }}
          >
            {Object.getOwnPropertyNames(ResourceType).map((key) => {
              return (
                <Select.Option key={key} value={key}>
                  {ResourceType[key]}
                </Select.Option>
              );
            })}
          </Select> */}
        </Form.Item>
        {currentType == '' && (
          <Form.Item shouldUpdate label="GPU资源" name={'GPU'} rules={[{ required: true }]}>
            <Select style={{ width: 200 }}>
              <Select.Option value="1">A100-SXM4-80GB</Select.Option>
              <Select.Option value="2">Tesla-T4</Select.Option>
              <Select.Option value="3">RTX-3090</Select.Option>
            </Select>
          </Form.Item>
        )}
        {currentType == '' && (
          <Form.Item shouldUpdate label="调整数量" name={'number'} rules={[{ required: true }]}>
            <Input style={{ width: 200 }} type="number" />
          </Form.Item>
        )}
        {currentType == '' && (
          <Form.Item shouldUpdate label="标签" name={'label'}>
            <Checkbox.Group options={plainOptions} />
          </Form.Item>
        )}
        {currentType == '' && (
          <Form.Item label="存储类型" name={'storage_type'} rules={[{ required: true }]}>
            <Select style={{ width: 200 }}>
              <Select.Option value="object">对象存储</Select.Option>
              <Select.Option value="filesystem">本地存储</Select.Option>
            </Select>
          </Form.Item>
        )}
        {currentType == '' && (
          <Form.Item label="容量（GiB）" name={'storage_size'} rules={[{ required: true }]}>
            <InputNumber type="number" />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default ResourceInfoDialog;
