import { Billing } from 'atom-proto/web/atom/v2/billing_pb';
import { Product } from 'atom-proto/web/atom/v2/product_pb';
import { formatMonth } from './timeUtil';

export function formatBillingDetail(billingList: Billing.AsObject[], resourceList: Product.AsObject[]) {
  const productMap = new Map(resourceList.map((product) => [product.productid, product.description || '']));

  return billingList.map((billing) => ({
    ...billing,
    detailsList: billing.detailsList.map((detail) => ({
      ...detail,
      productname: productMap.get(detail.productid) || ''
    }))
  }));
}

// 获取过去24个月的月份
export function getLast24Months(curMonth: Date) {
  const months = [];
  for (let i = 0; i < 24; i++) {
    const date = new Date(curMonth.getFullYear(), curMonth.getMonth() - i);
    months.push(`${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`);
  }
  return months.reverse();
}

// 对数据缺失日期用0进行填空
export function formatChartData(values: Billing.AsObject[] | undefined) {
  const latestData = values?.at(-1) || { billingmonth: { seconds: new Date().getSeconds() } };
  const curMonth = new Date(latestData.billingmonth.seconds * 1000);
  const monthList = getLast24Months(curMonth);

  const dataMap = new Map<string, { date: string; total: number; discount: number; paid: number }>();
  if (values) {
    values.forEach((value) => {
      const date = new Date(value?.billingmonth?.seconds * 1000);
      const formattedMonth = formatMonth(date);
      dataMap.set(formattedMonth, {
        date: formattedMonth,
        total: value?.total || 0,
        discount: value?.discount || 0,
        paid: value?.paid || 0
      });
    });
  }

  // 使用月份列表填充数据，若某个月份没有数据则使用默认值
  const filledDataArray = monthList.map((date) => {
    return dataMap.get(date) || { date, total: 0, discount: 0, paid: 0 };
  });

  return filledDataArray;
}

export interface BillingChartData {
  date: string;
  total: number;
  discount: number;
  paid: number;
}
