export const formatPrice = (value: number, prefix: string) => {
  return value > 0
    ? prefix +
        getNormalizedPrice(value).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
    : 0;
};

export const MICRO_UNIT_SCALE = 1000000;

export const getNormalizedPrice = (value: number) => {
  return value / MICRO_UNIT_SCALE;
};
