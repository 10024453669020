import { Tag } from 'antd';
import { useEffect } from 'react';
import { useStore } from 'utils/stateStore';
import { BlllingStatus } from 'utils/constants';
import { BillingStatus } from 'atom-proto/web/atom/v2/billing_pb';
class Status {
  color: string = '';
}
export const BillStatusTag = (props: { status: BillingStatus }) => {
  const { status } = useStore(new Status());
  useEffect(() => {
    if (props.status === BillingStatus.STATUSPAID) {
      status.color = 'green';
    } else if (props.status === BillingStatus.STATUSUNBILLED) {
      status.color = 'gray';
    } else if (props.status === BillingStatus.STATUSBILLED) {
      status.color = 'blue';
    } else if (props.status === BillingStatus.STATUSCANCELLED) {
      status.color = 'red';
    } else if (props.status === BillingStatus.STATUSUNKNOWN) {
      status.color = 'gray';
    }
  }, [props]);
  return (
    <Tag style={{ borderRadius: 6 }} color={status.color}>
      {BlllingStatus[props.status]}
    </Tag>
  );
};
