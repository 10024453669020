// source: meta/share.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var github_com_alta_protopatch_patch_go_pb = require('../github.com/alta/protopatch/patch/go_pb.js');
goog.object.extend(proto, github_com_alta_protopatch_patch_go_pb);
goog.exportSymbol('proto.meta.Access', null, global);
goog.exportSymbol('proto.meta.Access.Action', null, global);
goog.exportSymbol('proto.meta.Access.Rank', null, global);
goog.exportSymbol('proto.meta.ResourceAccess', null, global);
goog.exportSymbol('proto.meta.ResourceSharing', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.meta.ResourceAccess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.meta.ResourceAccess.repeatedFields_, null);
};
goog.inherits(proto.meta.ResourceAccess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.meta.ResourceAccess.displayName = 'proto.meta.ResourceAccess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.meta.ResourceSharing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.meta.ResourceSharing.repeatedFields_, null);
};
goog.inherits(proto.meta.ResourceSharing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.meta.ResourceSharing.displayName = 'proto.meta.ResourceSharing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.meta.Access = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.meta.Access, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.meta.Access.displayName = 'proto.meta.Access';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.meta.ResourceAccess.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.meta.ResourceAccess.prototype.toObject = function(opt_includeInstance) {
  return proto.meta.ResourceAccess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.meta.ResourceAccess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.meta.ResourceAccess.toObject = function(includeInstance, msg) {
  var f, obj = {
    sharedwithList: jspb.Message.toObjectList(msg.getSharedwithList(),
    proto.meta.ResourceSharing.toObject, includeInstance),
    rank: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.meta.ResourceAccess}
 */
proto.meta.ResourceAccess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.meta.ResourceAccess;
  return proto.meta.ResourceAccess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.meta.ResourceAccess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.meta.ResourceAccess}
 */
proto.meta.ResourceAccess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.meta.ResourceSharing;
      reader.readMessage(value,proto.meta.ResourceSharing.deserializeBinaryFromReader);
      msg.addSharedwith(value);
      break;
    case 2:
      var value = /** @type {!proto.meta.Access.Rank} */ (reader.readEnum());
      msg.setRank(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.meta.ResourceAccess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.meta.ResourceAccess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.meta.ResourceAccess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.meta.ResourceAccess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSharedwithList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.meta.ResourceSharing.serializeBinaryToWriter
    );
  }
  f = message.getRank();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated ResourceSharing sharedWith = 1;
 * @return {!Array<!proto.meta.ResourceSharing>}
 */
proto.meta.ResourceAccess.prototype.getSharedwithList = function() {
  return /** @type{!Array<!proto.meta.ResourceSharing>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.meta.ResourceSharing, 1));
};


/**
 * @param {!Array<!proto.meta.ResourceSharing>} value
 * @return {!proto.meta.ResourceAccess} returns this
*/
proto.meta.ResourceAccess.prototype.setSharedwithList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.meta.ResourceSharing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.meta.ResourceSharing}
 */
proto.meta.ResourceAccess.prototype.addSharedwith = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.meta.ResourceSharing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.meta.ResourceAccess} returns this
 */
proto.meta.ResourceAccess.prototype.clearSharedwithList = function() {
  return this.setSharedwithList([]);
};


/**
 * optional Access.Rank rank = 2;
 * @return {!proto.meta.Access.Rank}
 */
proto.meta.ResourceAccess.prototype.getRank = function() {
  return /** @type {!proto.meta.Access.Rank} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.meta.Access.Rank} value
 * @return {!proto.meta.ResourceAccess} returns this
 */
proto.meta.ResourceAccess.prototype.setRank = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.meta.ResourceSharing.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.meta.ResourceSharing.prototype.toObject = function(opt_includeInstance) {
  return proto.meta.ResourceSharing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.meta.ResourceSharing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.meta.ResourceSharing.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    recipientsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.meta.ResourceSharing}
 */
proto.meta.ResourceSharing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.meta.ResourceSharing;
  return proto.meta.ResourceSharing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.meta.ResourceSharing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.meta.ResourceSharing}
 */
proto.meta.ResourceSharing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.meta.Access.Action>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActions(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addRecipients(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.meta.ResourceSharing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.meta.ResourceSharing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.meta.ResourceSharing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.meta.ResourceSharing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getRecipientsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated Access.Action actions = 1;
 * @return {!Array<!proto.meta.Access.Action>}
 */
proto.meta.ResourceSharing.prototype.getActionsList = function() {
  return /** @type {!Array<!proto.meta.Access.Action>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.meta.Access.Action>} value
 * @return {!proto.meta.ResourceSharing} returns this
 */
proto.meta.ResourceSharing.prototype.setActionsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.meta.Access.Action} value
 * @param {number=} opt_index
 * @return {!proto.meta.ResourceSharing} returns this
 */
proto.meta.ResourceSharing.prototype.addActions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.meta.ResourceSharing} returns this
 */
proto.meta.ResourceSharing.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * repeated string recipients = 2;
 * @return {!Array<string>}
 */
proto.meta.ResourceSharing.prototype.getRecipientsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.meta.ResourceSharing} returns this
 */
proto.meta.ResourceSharing.prototype.setRecipientsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.meta.ResourceSharing} returns this
 */
proto.meta.ResourceSharing.prototype.addRecipients = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.meta.ResourceSharing} returns this
 */
proto.meta.ResourceSharing.prototype.clearRecipientsList = function() {
  return this.setRecipientsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.meta.Access.prototype.toObject = function(opt_includeInstance) {
  return proto.meta.Access.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.meta.Access} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.meta.Access.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.meta.Access}
 */
proto.meta.Access.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.meta.Access;
  return proto.meta.Access.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.meta.Access} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.meta.Access}
 */
proto.meta.Access.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.meta.Access.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.meta.Access.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.meta.Access} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.meta.Access.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.meta.Access.Rank = {
  UNKNOWN_ACCESS_RANK: 0,
  PUBLIC_DATA: 10,
  PROPRIETARY_DATA: 20,
  CONFIDENTIAL_DATA: 30
};

/**
 * @enum {number}
 */
proto.meta.Access.Action = {
  NONE: 0,
  GET: 1,
  LIST: 2,
  CREATE: 4,
  UPDATE: 8,
  DELETE: 16,
  RUN: 32,
  SHARE: 64,
  TOP: 128,
  SUBMIT: 256,
  REVIEW: 512,
  RELEASE: 1024,
  SEARCH: 2048
};

goog.object.extend(exports, proto.meta);
